import React from 'react'

const CountDownTimer = ({hoursMinSecs}) => {
   
    const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);
    

    const tick = () => {
   
        if (hrs === 0 && mins === 0 && secs === 0) 
            reset()
        else if (mins === 0 && secs === 0) {
            setTime([hrs - 1, 59, 59]);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 59]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }
    };


    const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

    
    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    
    return (
        <div class="clock-wrapper">
            <p class="mb-2">Next draw ends in:</p>
            <div class="clock" data-clock="2020/12/10">
                <div>
                    <span>{`${hrs.toString().padStart(2, '0')}`}</span>
                    <p>hours</p>
                </div>
                <div>
                    <span>{`${mins.toString().padStart(2, '0')}`}</span>
                    <p>minutes</p>
                </div>
                <div>
                    <span>{`${secs.toString().padStart(2, '0')}`}</span>
                    <p>seconds</p>
                </div>
            </div>
        </div>
        // <div>
        //     <p>{`${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}</p> 
        // </div>
    );
}

export default CountDownTimer;