import React, {useEffect, useState} from "react";
import {getConfig} from "../config";
import moment from 'moment';
import Select from 'react-select';


import prize_9 from "../assets/images/prizes/game_9.jpg";
import prize_8 from "../assets/images/prizes/game_8.jpg";
import prize_7 from "../assets/images/prizes/game_7.jpg";
import prize_6 from "../assets/images/prizes/game_6.jpg";
import prize_5 from "../assets/images/prizes/game_5.jpg";
import prize_4 from "../assets/images/prizes/game_4.jpg";
import prize_3 from "../assets/images/prizes/game_3.jpg";
import prize_2 from "../assets/images/prizes/game_2.jpg";
import prize_1 from "../assets/images/prizes/game_1.jpg";

import Loading from "../components/Loading";

export const Results = () => {

    const {apiOrigin = "https://api.luckyrewards.co"} = getConfig();
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        showResult: false,
        current_results: "",
        selected_date: "",
        results: "",
        error: null,
    });

    useEffect(() => {
        (async () => {
            await getResults();
            setLoading(false)
        })()
    }, []);

    const getResults = async () => {
        try {

            const response = await fetch(`${apiOrigin}/draws`, {});
            const responseData = await response.json();

            console.log('responseData', responseData)

            if(responseData && responseData.length != 0 ) {

                let select_options = []
                for (let d = 0; d < responseData.length; d++) {
                    const option = responseData[d];
                    select_options.push({value: d, label: moment(option.draw_date).format('DD MMM YYYY')});
                }

                
                let last_result = responseData.length-1
                let draw_date = responseData[last_result].draw_date
                let selected_date = {value: last_result, label: moment(draw_date).format('DD MMM YYYY')}               
                

                let game_1_numbers = responseData[last_result].rounds[0].draw_games[0] ? responseData[last_result].rounds[0].draw_games[0].numbers.split(",") : []
                let game_2_numbers = responseData[last_result].rounds[0].draw_games[1] ? responseData[last_result].rounds[0].draw_games[1].numbers.split(",") : []
                let game_3_numbers = responseData[last_result].rounds[0].draw_games[2] ? responseData[last_result].rounds[0].draw_games[2].numbers.split(",") : []
                let game_4_numbers = responseData[last_result].rounds[0].draw_games[3] ? responseData[last_result].rounds[0].draw_games[3].numbers.split(",") : []
                let game_5_numbers = responseData[last_result].rounds[0].draw_games[4] ? responseData[last_result].rounds[0].draw_games[4].numbers.split(",") : []
                let game_6_numbers = responseData[last_result].rounds[0].draw_games[5] ? responseData[last_result].rounds[0].draw_games[5].numbers.split(",") : []
                let game_7_numbers = responseData[last_result].rounds[0].draw_games[6] ? responseData[last_result].rounds[0].draw_games[6].numbers.split(",") : []
                let game_8_numbers = responseData[last_result].rounds[0].draw_games[7] ? responseData[last_result].rounds[0].draw_games[7].numbers.split(",") : []
                let game_9_numbers = responseData[last_result].rounds[0].draw_games[8] ? responseData[last_result].rounds[0].draw_games[8].numbers.split(",") : []
            

                let rounds = {
                    game_1_numbers,
                    game_2_numbers,
                    game_3_numbers,
                    game_4_numbers,
                    game_5_numbers,
                    game_6_numbers,
                    game_7_numbers,
                    game_8_numbers,
                    game_9_numbers,
                }

                setState({
                    ...state,
                    showResult: true,
                    results: responseData,
                    rounds: rounds,
                    select_options: select_options,
                    selected_date: selected_date,
                });
            }


        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    const handleDateSelect = (selections) => {
        
        let selected_option = null
        if(!!selections) selected_option = selections

        let draw_date = state.results[selected_option.value].draw_date
        let selected_date = {value: selected_option.value, label: moment(draw_date).format('DD MMM YYYY')}

        let game_1_numbers = state.results[selected_option.value].rounds[0].draw_games[0] ? state.results[selected_option.value].rounds[0].draw_games[0].numbers.split(",") : []
        let game_2_numbers = state.results[selected_option.value].rounds[0].draw_games[1] ? state.results[selected_option.value].rounds[0].draw_games[1].numbers.split(",") : []
        let game_3_numbers = state.results[selected_option.value].rounds[0].draw_games[2] ? state.results[selected_option.value].rounds[0].draw_games[2].numbers.split(",") : []
        let game_4_numbers = state.results[selected_option.value].rounds[0].draw_games[3] ? state.results[selected_option.value].rounds[0].draw_games[3].numbers.split(",") : []
        let game_5_numbers = state.results[selected_option.value].rounds[0].draw_games[4] ? state.results[selected_option.value].rounds[0].draw_games[4].numbers.split(",") : []
        let game_6_numbers = state.results[selected_option.value].rounds[0].draw_games[5] ? state.results[selected_option.value].rounds[0].draw_games[5].numbers.split(",") : []
        let game_7_numbers = state.results[selected_option.value].rounds[0].draw_games[6] ? state.results[selected_option.value].rounds[0].draw_games[6].numbers.split(",") : []
        let game_8_numbers = state.results[selected_option.value].rounds[0].draw_games[7] ? state.results[selected_option.value].rounds[0].draw_games[7].numbers.split(",") : []
        let game_9_numbers = state.results[selected_option.value].rounds[0].draw_games[8] ? state.results[selected_option.value].rounds[0].draw_games[8].numbers.split(",") : []
    

        let rounds = {
            game_1_numbers,
            game_2_numbers,
            game_3_numbers,
            game_4_numbers,
            game_5_numbers,
            game_6_numbers,
            game_7_numbers,
            game_8_numbers,
            game_9_numbers,
        }

        setState({
            ...state,
            rounds: rounds,
            selected_date: selected_date,
        });
    }

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#142066' : '',
        })
    }

    if(loading) return <Loading/>

    return (
        <section className="position-relative pt-120 pb-120 background-blue-contest">
            <div className="container pt-120">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="section-header text-center">
                            <h2 className="section-title">Past Game Results</h2>
                            <p className="section-sub-title">Showing Results For  {state.selected_date.label} </p>
                        </div>
                    </div>
                </div>

                {state.select_options &&
                <div className="row justify-content-center text-center mb-30">
                    <div className="col-lg-8">
                        <p className="mb-4 white">Select the date you want to see the results for:</p>
                        <Select options={state.select_options} styles={customStyles} onChange={handleDateSelect} value={state.selected_date} defaultValue={state.selected_date} />
                    </div>
                </div>
               }
                <div className="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div className="col-lg-12">
                        <div className="tab-content" id="contestTabContent">
                            <div className="tab-pane fade show active" id="car" role="tabpanel" aria-labelledby="car-tab">
                                <div className="row mb-none-30">
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_1} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_1_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_1_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end */}
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_2} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_2_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_2_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end */}
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_3} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_3_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_3_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end */}
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_4} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_4_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_4_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    {/* end */}
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_5} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_5_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_5_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end */}
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_6} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_6_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_6_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end */}
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_7} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_7_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_7_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end */}
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_8} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_8_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_8_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end */}
                                    {/* start */}
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <a href="contest-details-one.html" className="item-link"></a>
                                            <div className="contest-card__thumb">
                                                <img src={prize_9} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_9_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_9_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Results;
