import React, { useEffect} from 'react';

function PrivacyPolicy(props) {

    useEffect(() => {
    }, [])

    return (

            <div className="page-wrapper privacy background-blue-contest">
                <div className="container pt-120">
                    <div className="position-relative pt-120">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="section-header text-center">
                                    <h2 className="section-title">Privacy Policy</h2>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="row mb-30 justify-content-xl-start justify-content-center">
                                    <div className="col-lg-6 pr-4 pl-4">
                                        <h3 className="title">Cookies</h3>

                                        <h5>When it comes to cookies, our main policy is transparency.</h5>
                                        <h4>What are Cookies?</h4>

                                        <p>A cookie is a piece of information that is stored on your computer or smartphone whenever you visit a website. Your web browser (such as Google Chrome, Explorer, Firefox etc) will send these cookies back to each website you revisit, allowing the websites to ‘recognise’ you, and tailor the content you see accordingly.
                                        Your web browser is usually set by default to accept cookies in order to ensure that your browsing experience is as hassle-free as possible.</p>

                                        <h4>What are they used for?</h4>
                                        <p>Cookies are used to:</p>
                                        <ul>
                                        <li>provide users with a better online experience</li>
                                        <li>measure site visits and demographics of our users (what country, what time etc)</li>
                                        <li>measure the effectiveness of our marketing banners</li>
                                        <li>allow users to set personal preferences</li>
                                        <li>protect our users security</li>
                                        </ul>


                                        <h4>What information do they keep?</h4>
                                        <ul>
                                        <li>How long the cookie will stay on your browser</li>
                                        <li>The name of the website that it's come from</li>
                                        <li>A unique identification number </li>
                                        </ul>

                                        <h4>How can I control cookies?</h4>
                                        <p>If you would prefer not to receive any cookies, you can modify your browser to notify you when cookies are sent so that you can decide whether to accept them or not. This modification is dependent on the type of browser you are using; as such we encourage you to visit www.aboutcookies.org for detailed information on how to proceed safely.Please note that Lucky Rewards, do function without cookies, however you may lose certain functionality should you decide to disable them.</p>

                                        <h4>COOKIE POLICY</h4>
                                        <p>When using Lucky Rewards websites or mobile sites, your information may be gathered via cookie as outlined above. Please note that the cookies we collect are done so via third party websites for the following purposes:
                                        Social Media: Cookies that enable us to run social media components, like Facebook , Twitter and RSS feeds.
                                        Analytics: We keep track of popular pages and links in order to identify users’ navigation trends and keep our websites relevant. It is important to note that these cookies do not collect any information that may identify you. The information gathered is completely anonymous, showing only overall patterns of usage rather than one particular person’s activity. The marketing banners and emails used by Lucky Rewards also contain small images known as 'web beacons' or 'tracking pixels'. These are used to count the number of times a page or email has been viewed, allowing us to measure the effectiveness of our marketing strategy. These web beacons are also anonymous and do not contain or collect any personal information that may identify you. Furthermore, rest assured that Lucky Rewards does not sell data collected from cookies to any other organisations.
                                        Security: Some cookies help to ensure that your information is secure when browsing our website and using our services, keeping your online experience as safe and user-friendly as possible.
                                        Third party cookies: We work with a number of companies and affiliates in order to ensure that our advertising is served to potentially interested parties. These companies and partners may in turn also use cookies to collect anonymous and non-personal data, such as how many people may have viewed a particular page on a certain day, and their geographical location.</p>
                                    </div>
                                    <div className="col-lg-6 pr-4 pl-4">
                                        <h3 className="title">Privacy Policy</h3>
                                        <p>At Lucky Rewards, we are dedicated to safeguarding your privacy when browsing our websites and communicating electronically with us. This Privacy Policy provides an explanation as to how we secure any personal data that you may provide to us, or that we collect from you. Please note that as we update our Privacy Policy from time to time, it is in your best interests to review it regularly.</p>

                                        <h4>1. What information is stored?</h4>

                                        <p>We may collect, store and use the following kinds of personal data:
                                        Information about your computer, your visits to, and use of this website, including your IP address, geographical location, browser type, referral source, length of visit and number of page views.
                                        Information relating to transactions carried out between you and us, on or in relation to this website, including information relating to any purchases that you may make.
                                        Information that you provide to us for the purpose of registering with Lucky Rewards, including your name, physical address, email address and telephone details.
                                        Information that you provide to us for the purposes of subscribing to our website services, email notifications and/or newsletters including your demographics, location, gender, and age.
                                        Any other information that you choose to send to us.</p>

                                        <h4>2. Use of Your Information</h4>
                                        <p>The information that we collect and store relating to you is primarily used to enable us to provide our services to you. In addition, we may use the information for the following purposes:
                                        To provide you with information requested from us, relating to our products or services.  To provide information on other products which we feel may be of interest to you, where you have consented to receive such information. 
                                        To meet our contractual commitments to you.
                                        To notify you about any changes to our website, such as improvements or service/product changes, that may affect our service.
                                        If you are an existing customer, we may contact you with information on goods and services similar to those you have previously purchased. If you are a new customer, we will only contact you or allow third parties to contact you once you have provided consent, and only by those means for which you have you provided consent. Please be advised that we do not reveal information about identifiable individuals to our advertisers, however we may, on occasion, provide them with aggregate statistical information on our visitors.  We will not, without your express consent, distribute your personal information to any third parties for the purposes of direct marketing.</p>

                                        <h4>3. Disclosing Your Information</h4>
                                        <p>Where applicable, we may disclose your personal information to any member of our group. This includes, where applicable, our subsidiaries, our holding company and its other subsidiaries (if any). In addition, we may disclose information about you:
                                        Where we are legally required to disclose your information;
                                        In connection with any legal proceedings or prospective legal proceedings;
                                        In order to establish, exercise or defend our legal rights, including providing information to others for the purposes of fraud protection and minimize credit risk; 
                                        To the purchaser (or prospective purchaser) of any business or asset which we are (or are contemplating) selling.</p>

                                        <h4>4. International data transfers</h4>
                                        <p>Information that we collect may be stored and processed in, and transferred between any of the countries in which we operate, in order to enable us to use the information in accordance with this Privacy Policy.Information that you provide may be transferred to countries with different data protection laws to those in force in the European Economic Area (EEA). In addition, personal information that you submit for publication on our websites will be published on the internet and may be available, via the internet, around the world. By accepting this Privacy Policy you expressly agree to such transfers of personal information.</p>

                                        <h4>5. Security of Your Personal Data</h4>
                                        <p>From time to time we may transfer data that we collect from you to locations outside the European Economic Area for processing and storing. This data may be processed by staff operating outside the European Economic Area who work for us or for our suppliers. These staff may be engaged in the processing and concluding of your order, the processing of your payment details, or the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. Rest assured that we will take all reasonable steps to ensure that your data is treated securely and in agreement with this Privacy Policy.
                                        We will store all the personal information you provide on our secure (password and firewall-protected) servers. All electronic transactions you make or receive from us will be encrypted using SSL technology.The transmission of information via the internet is never completely secure and therefore we cannot guarantee the safety of data sent to us electronically. As such, transmission of such data is entirely at your own risk. Where we have given you (or where you have chosen) a username and password to access certain parts of our site, you will be responsible for keeping this password confidential.</p>

                                        <h4>6. Amendments</h4>
                                        <p>We may update this privacy policy from time-to-time by posting a new version on our website.  You should check this page occasionally to ensure you are happy with any changes. </p>

                                        <h4>7. Your rights</h4>
                                        <p>Please note that you may instruct us to provide you with any personal information that we hold about you, at any time. Provision of such information will be subject to the payment of a fee (currently fixed at £10.00). You may also instruct us via email not to process your personal data for marketing purposes. (In practice, you would usually either expressly agree in advance to our use of your personal data for marketing purposes, or we would provide you with an opportunity to opt out of the use of your personal data for marketing purposes.)</p>

                                        <h4>8. Third Party Links</h4>
                                        <p>You may find links to third party websites on our website from time to time. Should these websites have their own privacy policies, you will be expected to familiarise yourself with them. Due to these websites being outside our control, we cannot accept any responsibility or liability for their policies whatsoever.</p>

                                        <h4>9. Updating information</h4>
                                        <p>Please let us know if the personal information which we hold about you needs to be corrected or updated.</p>

                                        <h4>10. Contacting Us</h4>
                                        <p>We welcome requests you may have regarding this Privacy Policy.  Please contact us at info@Lucky Rewards.com</p>

                                        <h4>11. Data controller</h4>
                                        <p>The data controller responsible for our website is VidaHost.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default PrivacyPolicy;                