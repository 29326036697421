import React, {useEffect, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";

import logo from '../assets/images/logo/logo-landscape-white-new-yellow-small.png'
import { NavItem, NavLink} from "reactstrap";
import {NavLink as RouterNavLink} from "react-router-dom";
import $ from 'jquery';
import {getConfig} from "../config";

const Header = () => {

  /*====================================
        Header Sticky JS
   ======================================*/
   $(window).on('scroll', function () {
    if ($(this).scrollTop() > 1) {
      $('.header').addClass("sticky");
      $('.header').addClass("animated");
      $('.header').addClass("fadeInDown");
      $('.header').addClass("menu-fixed");

    } else {
      $('.header').removeClass("sticky");
      $('.header').removeClass("animated");
      $('.header').removeClass("fadeInDown");
      $('.header').removeClass("menu-fixed");
    }
    });

    const {apiOrigin = "https://api.luckyrewards.co"} = getConfig();
    const [nav_expanded, setNavExpanded] = useState(false)
    const [dropdown_expanded, setDropdownExpanded] = useState(false)

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithPopup,
        logout,
    } = useAuth0();

    const [state, setState] = useState({
        user: [],
        first_name: '',
        lastname: '',
        id_number: '',
        msisdn: '',
        subscribed_date: '',
        unsubscribed_date: '',
        isSubscribed: false
    });

    useEffect(() => {
        (async () => {
            await getUser();
        })()
    }, [isAuthenticated]);

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    const getUser = async () => {
        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${apiOrigin}/users/id`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const responseData = await response.json();
            const subscribedDate = responseData[0].subscribed_date
            const unSubscribedDate = responseData[0].unsubscribed_date
            let isSubscribed = false
            if (subscribedDate && !unSubscribedDate) {
                isSubscribed = true
            }

            setState({
                ...state,
                user: responseData[0],
                first_name: responseData[0].name,
                last_name: responseData[0].surname,
                msisdn: responseData[0].msisdn,
                id_number: responseData[0].id_number,
                subscribed_date: subscribedDate,
                unsubscribed_date: unSubscribedDate,
                isSubscribed: isSubscribed
            });
        }
    }

    const toggleNav = () => {
        // navbar-toggler
        if(!nav_expanded) {
            setNavExpanded(true)
            $('.navbar-toggler').removeClass('collapsed');
            $('.navbar-collapse').addClass('show');
        }

        if(nav_expanded) {
            setNavExpanded(false)
            $('.navbar-toggler').addClass('collapsed');
            $('.navbar-collapse').removeClass('show');
        }

    }

    return (
        <header className="header">
            <div className="header__top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6">
                        {isAuthenticated ?
                            <div className="left d-flex align-items-center">
                                <a href="#" onClick={() => logoutWithRedirect()} ><i className="las la-sign-out-alt white"></i> Logout</a>
                            </div>
                            :
                            <div className="left d-flex align-items-center white">
                                <a href="/contact"><i className="las la-envelope white"></i> Contact Support</a>
                            </div>
                        }
                        </div>
                        <div className="col-6">
                            <div className="right">
                                {isAuthenticated && (
                                    <a href="/profile">
                                        <img src={user.picture} alt="Profile" className="nav-user-profile rounded-circle" width="50"/>
                                    </a>
                                )}
                                {!isAuthenticated && (
                                    <a href="#" className="user__btn" onClick={() => loginWithPopup()}><i className="las la-user"></i></a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header__bottom">
                <div className="container">
                    <nav className="navbar navbar-expand-xl p-0 align-items-center">
                        <a className="site-logo site-title" href="/"><img src={logo} alt="Lucky Rewards Logo"/></a>
                        <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={nav_expanded} aria-label="Toggle navigation" onClick={toggleNav}>
                            <span className="menu-toggle"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav main-menu ml-auto">

                                    <NavItem>
                                        <NavLink
                                            tag={RouterNavLink}
                                            to="/"
                                            exact
                                            activeClassName="router-link-exact-active"
                                            onClick={toggleNav}
                                        >
                                            Home
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            tag={RouterNavLink}
                                            to="/results"
                                            exact
                                            activeClassName="router-link-exact-active"
                                            onClick={toggleNav}
                                        >
                                            Results
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            tag={RouterNavLink}
                                            to="/faqs"
                                            exact
                                            activeClassName="router-link-exact-active"
                                            onClick={toggleNav}
                                        >
                                            FAQs
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            tag={RouterNavLink}
                                            to="/contact"
                                            exact
                                            activeClassName="router-link-exact-active"
                                            onClick={toggleNav}
                                        >
                                            Contact
                                        </NavLink>
                                    </NavItem>
                                {isAuthenticated && (
                                    <NavItem>
                                        <NavLink
                                            tag={RouterNavLink}
                                            to="/my-entries"
                                            exact
                                            activeClassName="router-link-exact-active"
                                            onClick={toggleNav}
                                        >
                                            My Entries
                                        </NavLink>
                                    </NavItem>
                                )}
                                {state.isSubscribed && (
                                        <NavItem>
                                            <NavLink
                                                tag={RouterNavLink}
                                                to="vip-area"
                                                exact
                                                activeClassName="router-link-exact-active"
                                                onClick={toggleNav}
                                            >
                                                VIP Area
                                            </NavLink>
                                        </NavItem>
                                )}
                            </ul>
                            <div className="nav-right">
                                <a href="/game" className="cmn-btn style--three btn--sm">Play Now</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
};

export default Header;
