import React, { useState, useEffect } from "react";
import axios from 'axios';
import { getConfig } from "../config";
import Loading from "../components/Loading";

const { apiOrigin = "https://api.luckyrewards.co" } = getConfig();

const Contact = () => {

const [loading, setLoading] = useState(true)
const [name, setName] = useState("")
const [name_error, setNameError] = useState("")
const [email, setEmail] = useState("")
const [email_error, setEmailError] = useState("")
const [msisdn, setMsisdn] = useState("")
const [msisdn_error, setMsisdnError] = useState("")
const [message, setMessage] = useState("")
const [message_error, setMessageError] = useState("")
const [response_message, setResponseMessage] = useState(false)

useEffect(() => {
  (async () => {
    setLoading(false)
  })()
}, []);


const handleNameChange = (e) => {
  setName(e.target.value)
  setNameError("")
}

const handleEmailChange = (e) => {
  setEmail(e.target.value)
  setEmailError("")
}

const handleMsisdnChange = (e) => {
  setMsisdn(e.target.value)
  setMsisdnError("")
}

const handleMessageChange = (e) => {
  setMessage(e.target.value)
  setMessageError("")
}

const submit = async () => {
  if(name.length == 0 ) {
    setNameError("Please enter a valid Name")
    return
  }

  if(email.length == 0 ) {
    setEmailError("Please enter a valid Email")
    return
  }

  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  } else {
    setEmailError("Please enter a valid Email")
    return
  }

  if(msisdn.length == 0) {
    setMsisdnError("Please enter a valid mobile number")
    return
  }

  if(message.length == 0 ) {
    setMessageError("Please enter a message")
    return
  }

 let response = await axios.post(`${apiOrigin}/contact`, {
   name: name,
   email: email,
   phone: msisdn,
   message: message
  })

  console.log(response)
  console.log(response.data)

  if(response.status == 200 && response.data.status == 2 ) {

    setName()
    setEmail()
    setMsisdn()
    setMessage()
    setResponseMessage(true)

  }
}

if(loading) return <Loading/>

return(
  <section className="position-relative pt-120 pb-120 background-blue-contest">
    <div className="container">
      <div className="row justify-content-center pt-120">
        <div className="col-lg-12">
          <div className="section-header text-center">
            <h2 className="section-title">Contact</h2>
            {!response_message ?
            <p>Please complete the form below and one of our customer care agents will get back to you as soon as possible.</p>
            :
            <p>Thank you for sending us a message we’ll respond as soon as possible.</p>
            }
          </div>
        </div>

      {!response_message &&
        <div className="col-lg-12">
          <div className="contact-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-form-wrapper">
                  <h3 className="title">Drop us a message</h3>
                    <div className="contact-form" id="contact_form_submit">
                      <div className="form-group">
                        <label>Name <sup>*</sup></label>
                        <input type="text" name="name" id="name" value={name} placeholder="Enter your full name" onChange={handleNameChange}/>
                        {name_error.length != 0 && <p className="input_error">{name_error}</p>}
                      </div>
                      <div className="form-group">
                        <label>Email <sup>*</sup></label>
                        <input type="email" name="email" id="email" value={email} placeholder="Enter your email address" onChange={handleEmailChange}/>
                        {email_error.length != 0 && <p className="input_error">{email_error}</p>}
                      </div>
                      <div className="form-group">
                        <label>Mobile Number <sup>*</sup></label>
                        <input type="text" name="msisdn" id="msisdn" value={msisdn} placeholder="Enter your mobile number" onChange={handleMsisdnChange}/>
                        {msisdn_error.length != 0 && <p className="input_error">{msisdn_error}</p>}
                      </div>
                      <div className="form-group">
                        <label>Message <sup>*</sup></label>
                        <textarea name="message" id="message" value={message} placeholder="Leave us a message" onChange={handleMessageChange}></textarea>
                        {message_error.length != 0 && <p className="input_error">{message_error}</p>}
                      </div>
                      <div className="form-group">
                        <button type="submit" className="cmn-btn w-100" onClick={() => submit()}>send message</button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }

      </div>
      </div>
  </section>
  )
};

export default Contact;
