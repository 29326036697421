import React, {useEffect, useState} from "react";
import {getConfig} from "../config"; 
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";

export const VIP = () => {

    const { apiOrigin = "https://api.luckyrewards.co" } = getConfig();

    const {
        getAccessTokenSilently
    } = useAuth0();

    const [state, setState] = useState({
        user: [],
        user_id: '',
        first_name: '',
        lastname: '',
        id_number: '',
        msisdn: ''
    });

    useEffect(() => {
        (async () => {
            await getUser();
        })()
    },[]);

    const getUser = async () => {

        const token = await getAccessTokenSilently();
        const response = await fetch(`${apiOrigin}/users/id`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        const responseData = await response.json();
        console.log(responseData[0])

        setState({
            ...state,
            user: responseData[0],
            user_id: responseData[0].id,
            first_name: responseData[0].name,
            last_name: responseData[0].surname,
            msisdn: responseData[0].msisdn,
            id_number: responseData[0].id_number
        });
    }

    

    const handle = (e, fn) => {
        e.preventDefault();
        fn();
    };

    return (
        <section className="hero background-blue-hero">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-start">
                    <div className="col-lg-6 col-md-8">
                        <div className="hero__content">
                            <div className="hero__subtitle">Get more value and rewards</div>
                            <h2 className="hero__title">JOIN VIP</h2>
                            <div className="hero__btn">
                                <a href={"http://doi.mtndep.co.za/service/8428/?ext_ref=" + state.user_id} className="cmn-btn style--two">Join VIP</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-8">
                        <div className="hero__content">
                            <ul>
                                <li>Access to the VIP Area where you can play additional games</li>
                                <li>Automatic lucky reward number generation everyday if you forget to play</li>
                                <li>We will call and email you if you have winning numbers, no need to come back and check</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withAuthenticationRequired(VIP, {
    onRedirecting: () => <Loading />,
});
