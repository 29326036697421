import React from "react";
import logo from "../assets/images/logo/logo-landscape-white-new-yellow-small.png";

const Footer = () => (
    <footer className="footer-section">
        <div className="container pt-70">
            <div className="row pb-5 align-items-center">
                <div className="col-lg-4">
                    <ul className="app-btn">
                        <li><img src={logo} alt="Lucky Rewards Logo"/></li>
                    </ul>
                </div>
                <div className="col-lg-8">
                    <ul className="short-links justify-content-lg-end justify-content-center">
                        <li><a href="/faqs">FAQs</a></li>
                        <li><a href="/contact">Contact</a></li>
                        <li><a href="/terms">Terms of Services</a></li>
                        <li><a href="/privacy">Privacy</a></li>
                    </ul>
                </div>
            </div>
            <hr/>
            <div className="row py-5 align-items-center">
                <div className="col-lg-6">
                    <p className="copy-right-text text-lg-left text-center mb-lg-0 mb-3">Copyright © 2021.All Rights Reserved</p>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
