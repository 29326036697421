import React, {useLayoutEffect, useState} from "react";

import Hero from "../components/Hero";
import HowToPlay from "../components/HowToPlay";
import Prizes from "../components/Prizes";
import Loading from "../components/Loading";

export const Home = () => {
    const [loading, setLoading] = useState(true)
    useLayoutEffect(() => {
        (async () => {
            setLoading(false)
        })()
    }, []);

    return (

        loading ? 
            <Loading/> 
        :
        <>
            <Hero/>

            <HowToPlay/>

            <Prizes/>

        </>
    )
};

export default Home;
