import React from 'react';

function TermsConditions(props) {

    return (
            <div className="page-wrapper privacy background-blue-contest">
                <div className="container pt-120">
                    <div className="position-relative pt-120">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="section-header text-center">
                                    <h2 className="section-title">Terms & Conditions</h2>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="row mb-30 justify-content-xl-start justify-content-center">
                                    <p><strong>Premium Content Service & Free Competition Terms and Conditions (“Terms and Conditions”)</strong><br></br><br />
                                    <strong>NOTE:</strong><br></br>
                                    <strong>A. </strong>To the extent that the Terms and Conditions or any goods or services provided under the Terms and Conditions are governed by the Consumer Protection Act, 2008 (the "Consumer Protection Act"), no provision of the Terms are intended to contravene the applicable provisions of the Consumer Protection Act, and therefore all provisions of the Terms and Conditions must be treated as being qualified, to the extent necessary, to ensure that the applicable provisions of the Consumer Protection Act are complied with.<br></br>
                                    <strong>B. </strong>Please read these terms and conditions carefully. Your use of these services will constitute your agreement to comply with these terms and conditions. If you do not agree with these terms and conditions, please do not use these services. It is your obligation to review these Terms and Conditions regularly.<br></br>
                                    <strong>C. </strong>Participation in this offer will constitute your agreement to comply with these Terms and Conditions.<br></br><br></br>
                 
                                    <strong>SECTION 49 CONSUMER PROTECTION ACT NO 68 of 2008 NOTICE, THE FOLLOWING TERMS ARE IMPORTANT TO NOTE:Clauses: 2.2, 8, 6.3 and 7.</strong><br></br><br></br>
                                    <strong>1. Introduction</strong><br></br><br />
                                    LR provides Participants with access to Informative and Entertainment content Services, which consists of Subscription to the Premium Content Service and a limited time Free Competition Offer as detailed in these Terms and Conditions. (the “Offering”).<br></br><br></br>
                                    
                                    <strong>2. Service Period</strong><br></br><br />
                                    2.1 The Premium Content Service is available from 1 November 2017 until such time as advised by LR; and2.2 The Free Competition Offer is available from 1 November 2017 until such time as advised by LR.(the “Offer Period”);2.3 Notwithstanding the Offer Period, LR may, amend the duration of the Offer Period to a shorter or longer period, or withdraw the Offering in its entirety, with notice to you.<br></br><br></br>
                                    
                                    <strong>3. Who is Eligible</strong><br></br><br />
                                    3.1 To be eligible to participate, the participant (“Participant“) must:3.1.2 be a natural person; and3.1.3 be 18 (eighteen) years old or older.<br></br>
                                    3.2 Participation in this Offering excludes employees, directors, members, partners, consultants and agents of, or any other person who, directly or indirectly controls or is controlled by LR or marketing service providers of this Offering, (and the spouses, life partners, immediate family members or business partners of the people or entities listed above. This means:3.2.1 supplier/s of goods and or services in terms of this Offering; and 3.2.2 promotional partners, printers, advertising and promotional agencies, professional advisors and point of sale staff employed by or contracted to, or providing goods or services of any kind, to all the people or entities listed above during the Offer Period.<br></br><br></br>

                                        
                                    <strong>4. How to qualify for the Free Competition Offer?</strong><br></br><br />
                                    4.1 Participants must subscribe to the Premium “Lucky VIP” membership to qualify for the Free Competition Offer.<br></br>
                                    4.2 For each day that a Participant remains a subscriber to the Premium “Lucky VIP” membership the Participant shall receive 14 free entries in aggregate into SEVEN (7) lottery- style draws of increasing prize value and be notified by SMS/ Email and phone call if the Participant has won a Prize.<br></br>
                                    4.3 To Opt out, the Participant will have to dial *155#<br></br><br></br>

                                    <strong>5. How to qualify for the Premium “Lucky VIP” membership?</strong><br></br><br />
                                    5.1 To access this Offering, the Participant will login into the website and following the instructions on https://www.luckyrewards.com/ for further subscription options.<br></br>
                                    5.2 The Participant will be charged R3 (three Rand) per day for the Premium “Lucky VIP” membership. <br></br>
                                    5.3 The Premium Content consists of the following services 1) daily horoscopes 2) other premium content including, but not limited to, breaking news and sports content .<br></br>
                                    5.4 To Opt out, the Participant will have to dial *155#<br></br><br></br>

                                    <strong>6. Draw</strong><br></br><br />
                                    6.1 Winners will be notified either by email, telephonically or SMS by LR on the day of the draw or as soon as possible after the draw.<br></br>
                                    6.2 Participants acknowledge and accept that LR may utilize a third party (the authorized agent/s) to contact the Participant, in the event that the Participant is a winner. In order to effect the contacting and payment process, LR shall provide the Participant’s information to such third party.<br></br><br></br>
                                    
                                    <strong>7. General</strong><br></br><br />
                                    7.1 LR, its respective directors, affiliates, members, partners, employees, agents, consultants, suppliers, contractors, subsidiaries and sponsors assume no liability whatsoever for any direct or indirect loss or damage, including but not limited to physical harm or death, howsoever arising from or as a result of an Participant’s participation in the Offering or the Participant’s redemption of any Prize in terms of this Offering, or from any amendments to Prize details and/or the terms and conditions of this Offering. All Participants (including the winners) hereby expressly indemnify LR in this regard and shall hold it harmless from all and any claims arising from or as a result of the above reasons.<br></br>
                                    7.2 In so far as it is necessary, required by law or beyond the reasonable control of LR, LR reserves the right to vary the nature of the Offering, the Prize, or these terms and conditions upon notice in this regard being published on www.Lucky Rewards.com or in another appropriate medium. In the event that the Prize is limited or varied in this manner, LR shall replace the Prize with a prize of a similar economic value.<br></br>
                                    7.3 With regard to clause 7.2 above, any amended terms and conditions published in any media, or on the LR website will form part of the terms and conditions of the Offering, to which terms the Participants agree to be bound.<br></br>
                                    7.4 Winners shall be contacted on the cell phone number and/ or via the medium it used to enter or in terms of any contact details supplied by the winner when entering. LR shall attempt to contact the winner for a period of 1 (one) month after his or her name is drawn as a winner. In the event that the winner is either uncontactable (during the time frames stipulated in this clause 7.4) or in the event that the winner rejects, or declines acceptance of the Prize, the Prize shall be subject to a new draw.<br></br>
                                    7.5 The Prize is not transferable and is not exchangeable for another prize or cash and cannot be sold.<br></br>
                                    7.6 The decision of LR in respect of disputes arising out of this Offering shall be dealt with by LR in terms of these terms and conditions. The decision of LR in this regard shall be final.<br></br>
                                    7.7 LR reserves the right to withhold a Prize until it is entirely satisfied that the claimant of a Prize is the bona fide winner, and reserves the right to call for such proof as it may deem necessary.<br></br>
                                    7.8 LR shall request that winners consent in writing to their name, image and likeness being used and published by LR in connection with this Offering for a period of 12 (twelve) months after they are announced as winners. Winners may decline the use of their name, likeness and image by LR.<br></br>
                                    7.9 By participating in this Offering Participants signify their consent to be bound by the terms and conditions contained herein. If a winner is under the age of 18 years (a “minor”), the winner’s guardian or parent will be required to assist the minor with all and any necessary documents or agreements in connection with being able to take up any of the Prize/s in this Offering.<br></br>
                                    7.10 By participating in this Offering all Participants give their consent to receive various marketing and promotional material from LR. Participants will be provided with an opportunity to “Opt Out” of receiving such communications, which may be via the relevant medium that such marketing communication was received.<br></br>
                                    7.11 Winners agree that it is an express condition of the Offering that in order to be eligible to redeem a Prize, a winner shall be required to sign the appropriate acknowledgement of receipt of the Prize, as well as an indemnity and/or waiver of liability as reflected in these terms.
                                    General Terms & Conditions<br></br><br></br>
                                    Lucky Rewards.Com, is a Site (the “Site”) owned and operated by Company Registration Number HE351229, with address Nicosia, Cyprus
                                    Lucky Rewards.Com can be described as seven (7) fun, easy to play, daily lottery-style competition games which offers players 2 chances to win daily cash prizes in the first six games as well as two (2) daily entries into our daily “Grand Prize” draw (the “Services”).
                                    By accessing and using the Site, you signify that you have read, fully understand and agree to be legally bound by these Terms and Conditions (the “Conditions”) and to comply with all applicable laws and regulations as they may change from time to time. IF YOU DO NOT ACCEPT AND AGREE TO BE LEGALLY BOUND BY AND COMPLY WITH THESE CONDITIONS, YOU ARE NOT PERMITTED TO ACCESS OR USE THE SITE.
                                    We reserve the right, in our sole and absolute discretion, to modify all or a portion of these Conditions at any time without further notice and without incurring any liability or obligation. If we do this, we will post the changes to these Conditions on the Site and will indicate the date these Conditions were last revised. Your continued access to and/or use of the Site after any such changes constitutes your acceptance of, and agreement to be legally bound by, these Conditions as revised. It is your sole responsibility to regularly check the Site to determine if there have been any changes to these Conditions and to review such changes.
                                    You hereby acknowledge and agree that we reserve the right at any time to modify or discontinue the whole, or any part of, the Site, without notice, and that we will not be responsible or liable, directly or indirectly, to you or any other person or entity for any loss or damage of any kind incurred as a result of any such modifications or discontinuance.<br></br><br></br>
                                    
                                    <strong>1. Use of the Lucky Rewards.com Site</strong><br></br><br />
                                    You may use the Service, the Site, and the information, writings, images and/or other works that you see, hear or otherwise experience on the Site (singly or collectively, the “Content”) solely for your non-commercial, personal purposes and/or to learn about Lucky Rewards and/or its sponsors’ products and services. No right, title or interest in any Content is transferred to you, whether as a result of downloading such Content or otherwise. Lucky Rewards reserves complete title and full intellectual property rights in all Content. Except as expressly authorized by this Agreement, you may not use, alter, copy, distribute, transmit, or derive another work from any Content obtained from the Site or the Service, except as expressly permitted by these Conditions.<br></br><br></br>
                                    
                                    <strong>2. Copyright and Intellectual Property</strong><br></br><br />
                                    2.1 Copyright<br></br>
                                    You should assume that everything you see or read on the Site is copyrighted unless otherwise noted and may not be used except as provided in these Terms and Conditions or in the text on the Site without the written permission of Lucky Rewards . Lucky Rewards neither warrants nor represents that your use of materials displayed on the Site will not infringe rights of third parties not owned by or affiliated with Lucky Rewards.
                                    2.2 Intellectual Property<br></br>
                                    The names, images, pictures, logos and icons identifying Lucky Rewards and its Services are, unless otherwise stated, its intellectual property.  Other products, pictures, images, logos, and icons and company names mentioned on the Site may belong to other companies. 
                                    All intellectual property rights in the design, content and arrangement of the Site (including its text, domain name and graphics, all software compilations or underlying source code, and all other material on the Site) are reserved by Lucky Rewards  or its content and/or technology providers.
                                    Players may not use any materials contained on the Site for any purpose other than in connection with the Services.  Players may take a copy of these Conditions, which must be used strictly for their own records.
                                    Access to the Site is on an "as is" basis and Lucky Rewards  gives no further warranties, whether express, implied or statutory including, but without limitation, the implied warranties of satisfactory quality or fitness for a particular purpose of the Site or the availability of the Site.<br></br><br></br>
                                    
                                    <strong>3. Trademarks</strong><br></br><br />
                                    You are prohibited from using any of the marks or logos appearing throughout the Site without permission from the trademark owner, except as permitted by applicable law.<br></br>
                                    
                                    <strong>4. Linking to this Site</strong><br></br><br />
                                    Unless specifically authorized by Lucky Rewards, you may not connect deep links to the Site, i.e., create links to this Site that bypass the home page or other parts of the Site. You may not mirror or frame the home page or any other pages of this Site on any other website or web page without Lucky Rewards ’s authorization.<br></br><br></br>
                                    
                                    <strong>5. Player Account Registration</strong><br></br><br />
                                    Players will be required to create an ID and password to open an account with Lucky Rewards. Instructions for registration can be found on the “Register Now” section of the Site. Players are asked to ensure that their player ID and password remain strictly private and confidential at all times. Players must notify Lucky Rewards immediately of any unauthorised use or suspected unauthorised use of their player ID or password, or of any breach of security known to them. Players are responsible for all activities which occur under their player ID and password, save where such activities occur as a result of Lucky Rewards negligence.
                                    Players must satisfy the following criteria for registration on the Site: 
                                    It is lawful for them to receive the Services from Lucky Rewards in the jurisdiction in which they are located;
                                    Aged at least 18 years of age; and<br></br>
                                    Provide a valid full name, contact telephone number and email address.
                                    Players residing or present in any jurisdiction where receipt of the Services would be a prohibited activity are not permitted to register on this site or receive the Services.
                                    Lucky Rewards  its agents and anyone on their behalf make no representations or warranties, expressed or implied, as to the lawfulness of any player’s participation in any activity through the Site including, but without limitation to, the legality of the receipt of Services by players in their residing jurisdiction. 
                                    Lucky Rewards  reserves the right to require players to verify that they are eligible to register on the Site, to carry out checks or request information from players from time to time (during and after registration) and to refuse, suspend or cancel registration of an account in its absolute discretion (for example, including but not limited to if a player provides any false, invalid or misleading information).
                                    On completion of registration, players will be notified by email that their account is open and they will be able to play the games using the Lucky Rewards .com Site, in accordance with these Conditions.<br></br><br></br>
                                    
                                    <strong>6. Account Cancellation</strong><br></br><br />
                                    Players can cancel their account at any time by sending an email to <a href="mailto:info@LuckyRewards.com">info@LuckyRewards.com</a> stating that they wish to cancel their player account.  If a player cancels their account, or if Lucky Rewards suspends or cancels an account, any winnings held within a player’s online member account will remain in their account until verified payment instructions are received. <br></br>
                                    6.1 Refunds<br></br>
                                    In the case of credit/ debit card subscriptions, the player acknowledges that-
                                    (a) Lucky Rewards  CANNOT refund a portion of a transaction made back to the players credit card or other financial institution.(b) Should Lucky Rewards  deem that an error has been made during a transaction, the transactions will only be VOIDED within a maximum of eight (8) hours time-frame from player submission, and before the transaction is processed by our acquiring bank. Should a player query a transactions after this time frame, Lucky Rewards cannot VOID the transaction.
                                    No refund and/or amendment is available once Lucky Rewards has received a player’s instructions to proceed with the purchase of a subscription. Submissions or Subscription Requests can be amended until players click on the “Confirm Payment” link, after which time they cannot be amended or refunded, and/or instructions to provide the additional services, will have been sent via Lucky Rewards ’s secure server<br></br><br></br>
                                    
                                    <strong>7. Free Play</strong><br></br><br />
                                    Site users have the opportunity to win a prize, or the “Grand Prize” (the “Grand Prize”), in the following manner:
                                    Players can only have one (1) account. Maintaining more than one account will invalidate all entries
                                    New players qualify for the next available daily draw as soon as they register.
                                    Upon registration players gain free entry to play Games 1 to 7 manually.
                                    Players manually select one (1) line in each of the seven (7) competition games, which includes a maximum of two (2) free entries per day into their daily “Grand Prize” draw.
                                    Once the first seven (7) games have been played, the player has to go back to the start in order to start their second entry.
                                    The player only gets a maximum of two (2) free entries per day into the daily “Grand Prize” draw.
                                    Players may view all their entries on Lucky Rewards .com anytime.
                                    Entries are electronically stored in a player’s Lucky Rewards account and are only valid for the draw stated on that entry.
                                    Draws take place at [19:00 Central European Time] each day.
                                    Players are obligated to check their results manually on a daily basis. Results are published on the “My Entries/Results” page of Lucky Rewards .com and players should check their entries after each draw to find out whether they have won a prize.
                                    A player must make use of the corresponding winning reference number in order to claim a prize and must contact us within five (5) days of the relevant draw to start the pay-out process. Prizes are claimed by sending an email to <a href="mailto:info@LuckyRewards.com">info@LuckyRewards.com</a> confirming the name of the competition game, the date of the game played and your matching numbers. *Please note that players who deliberately attempt to mislead or make false winning claims will have their accounts closed immediately. 
                                    All Lucky Rewards games are free as sponsored advertising is offered during game play.
                                    Players should note that the Grand Prize may be presented in person for publicity purposes.<br></br><br></br>
                                    
                                    <strong>8. Winnings</strong><br></br><br />
                                    Winnings will be made to the players bank account in the player’s choice of South African Rand, Euros, American dollars, Australian Dollars, or Pounds sterling.  Winnings will be paid at the rate of exchange given through Lucky Rewards ’s money transferring company and/or banking institution less any international bank fees charged.
                                    Cash prizes, with the exception of the Grand Prize, will be paid out within thirty (30) days of claiming your prize to the banking institution provided by the player less international bank fees, where applicable.
                                    We do not issue said cash payouts through any other medium. Lucky Rewards .com will only attempt to payout said cash prizes one (1) time and will not be held responsible for any malfunction, error, cancellation, fee or any other problem with your given bank account, or any other reason whatsoever.
                                    The Grand Prize will be paid in 25 graduated payments over 25 years and in the same way as official USA lottery jackpots are usually remitted. Annuity payments will be made annually close to the anniversary date of the claim. Specifics of the Grand Prize shall be solely determined by the Sponsor.
                                    The player agrees that on registration they must provide a valid and correct full name, contact number and email address. Should either an incorrect name, nickname, invalid telephone number or email be provided, any winnings pertaining to that account will be automatically forfeited.
                                    Anti-Money Laundering - In our genuine attempt to protect our player’s funds, we work according to Anti-Money Laundering laws and regulations when adhering to any form of player payout/ fund withdrawal and may require documents listed below to be submitted to our accounts department before the release of such pay-outs irrespective of amount or consistency. Our accounts department will then ensure that the documents provided are relevant and valid according to international standards. Documents required: Proof of Identity – A full coloured scanned copy of your passport, driver license or a government issued ID card; Proof of Address – A valid utility bill such as water, electricity or gas bill. The bill should not be more than 3 months old. For more information, contact your account manager or our support team on <a href="mailto:info@LuckyRewards.com">info@LuckyRewards.com</a><br></br><br></br>
                                    
                                    <strong>9. Unclaimed Winnings</strong><br></br><br />
                                    Any and all unclaimed Winnings will be retained by Lucky Rewards for 6 months.
                                    If Lucky Rewards , having endeavoured to contact the player by means of any email address and phone number specified in his/her player account, is unable to pay out the relevant Winnings to the relevant player within such 6 month period, then
                                    The relevant player shall cease to be entitled to claim such Winnings; an
                                    The Winnings shall either be forfeited to Lucky Rewards or Lucky Rewards  shall be entitled to donate the unclaimed Winnings to a charity of its choice.<br></br><br></br>
                                    
                                    <strong>10. Optional Added Benefit Services</strong><br></br><br />
                                    There is an additional service offered by Lucky Rewards for which players are required to pay. This Optional Added Benefit Services (“Optional Added Benefit Service“) comprise the following optional extras:
                                    10.1 Monthly Subscription
                                    Two (2) x Automatic daily entries into each draw play (instead of a manual entry). One entry is comprised of the players choice of numbers, the next is randomly generated. 
                                    Automatic results checker
                                    Guaranteed prize claimer
                                    “You are a Winner!” email notification.
                                    Telephone notification for prizes of 200 GBP or currency equivalent. 
                                    Hot Number Wizard<br></br>
                                    The aforementioned Optional Added Benefit Services are added extra concierge-style services offered by Lucky Rewards and players are under no obligation to make use of or subscribe for these services.
                                    Should a player sign up for one of the Optional Added Benefit Services, monthly recurring service begins as soon as the player’s initial payment is successfully processed. The player will be charged within that calendar month and then charged the rate stated at the time of purchase, every month, on the 1st of each month until the player cancels. Cancellations of recurring subscriptions can be made any time by visiting your “My Account” tab after login and clicking on the “Stop Subscription’’ button. Otherwise a player may cancel his/her subscription by contacting us via the ‘’Contact Us’’ link.<br></br><br></br>
                                    
                                    <strong>11. Downloading Files</strong><br></br><br />
                                    Lucky Rewards cannot and does not guarantee or warrant that files available for downloading through the Site will be free of infection by software viruses or other harmful computer code, files or programs.<br></br><br></br>
                                    
                                    <strong>12. Software</strong><br></br><br />
                                    Any software available for download via the Site is the copyrighted work of Lucky Rewards and/or its licensors. Use of such software is governed by the terms of the end user license agreement that accompanies or is included with the software. Downloading, installing, and/or using any such software indicates your acceptance of the terms of the end user license agreement.<br></br><br></br>
                                    
                                    <strong>13. Fraud</strong><br></br><br />
                                    In the event of any player being engaged in any kind of fraudulent behaviour (in Lucky Rewards ’s reasonable and sole opinion) in relation to these Conditions, Lucky Rewards  reserves the right to close the player’s account at any time without prior notice or explanation, and any winnings still held in that player’s account shall be forfeited to Lucky Rewards .com.
                                    A player may not enter more times than indicated by using multiple email addresses, identities or devices in an attempt to circumvent the Conditions. If you use fraudulent methods or otherwise attempt to circumvent the Conditions your account may be removed from eligibility at the sole discretion of Lucky Rewards and you may be banned from further participation. 
                                    Players are not permitted to use or access the Site from any jurisdiction in which it is unlawful to do so.<br></br><br></br>
                                    
                                    <strong>14. Disclaimer of Warranties</strong><br></br><br />
                                    LUCKY REWARDS MAKES NO EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER WITH RESPECT TO THE SITE, THE SERVICE OR THE CONTENT. TO THE EXTENT PERMITTED BY THE APPLICABLE LAW, LUCKY REWARDS  EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, WITH REGARD TO THE SITE, THE SERVICE, THE CONTENT, AND ANY PRODUCT OR SERVICE FURNISHED OR TO BE FURNISHED VIA THE SITE. LUCKY REWARDS DOES NOT WARRANT THAT THE FUNCTIONS PERFORMED BY THE SITE OR THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, OR THAT DEFECTS IN THE SITE OR THE SERVICE WILL BE CORRECTED. LUCKY REWARDS DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE CONTENT, OR THAT ANY ERRORS IN THE CONTENT WILL BE CORRECTED. THE SITE, THE SERVICE AND THE CONTENT ARE PROVIDED ON AN AS IS AND AS AVAILABLE BASIS.<br></br><br></br>
                                    THE SITE, THE SERVICE AND THE CONTENT ON THE SITE ARE OFFERED IN JURISDICTIONS WHERE THEY MAY BE LEGALLY OFFERED. THE INFORMATION ON THE SITE IS NOT AN OFFER OR SOLICITATION BY ANYONE IN ANYONE IN ANY JURISDICTION IN WHICH AN OFFER OR SOLICITATION CANNOT LEGALLY BE MADE, OR TO ANY PERSON TO WHOM IT IS UNLAWFUL TO MAKE A SOLICITATION.<br></br><br></br>
                                    
                                    <strong>15. Limitation of Liability</strong><br></br><br />
                                    IN NO EVENT WILL LUCKY REWARDS  BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING, BUT NOT LIMITED TO ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR OTHER INDIRECT DAMAGES ARISING OUT OF (I) THE USE OF OR INABILITY TO USE THE SITE, THE SERVICE, OR THE CONTENT, (II) ANY TRANSACTION CONDUCTED THROUGH OR FACILITATED BY THE SITE; (III) ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THE SITE, THE SERVICE AND/OR THE CONTENT, (IV) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, OR (V) ANY OTHER MATTER RELATING TO THE SITE, THE SERVICE, OR THE CONTENT, EVEN IF LUCKY REWARDS  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOU ARE DISSATISFIED WITH THE SITE, THE SERVICE, THE CONTENT, OR WITH THE CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE. YOU EXPRESSLY AGREE THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY AND THE DISCLAIMERS SET FORTH HEREIN WILL SURVIVE, AND CONTINUE TO APPLY IN THE CASE OF A FUNDAMENTAL BREACH OR BREACHES, THE FAILURE OF ESSENTIAL PURPOSE OF CONTRACT, THE FAILURE OF ANY EXCLUSIVE REMEDY, OR TERMINATION OF THIS AGREEMENT.<br></br><br></br>
                                    
                                    <strong>16. Indemnification</strong><br></br><br />
                                    YOU UNDERSTAND AND AGREE THAT YOU ARE PERSONALLY RESPONSIBLE FOR YOUR BEHAVIOUR ON THE SITE. YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS LUCKY REWARDS , ITS PARENT COMPANIES, SUBSIDIARIES, AFFILIATED COMPANIES, JOINT VENTURERS, BUSINESS PARTNERS, LICENSORS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS ANY PRIZE SUPPLIERS AND ANY OTHER ORGANIZATIONS OR PERSONS RESPONSIBLE FOR SPONSORING, FULFILLING, ADMINISTERING, ADVERTISING OR PROMOTING ANY SERVICE, INCLUDING WITHOUT LIMITATION ANY COMPETITIONS OR CONTESTS, AND ANY THIRD-PARTY INFORMATION PROVIDERS TO THE SERVICE (COLLECTIVELY, INDEMNIFIED PARTIES) FROM AND AGAINST ALL CLAIMS, LOSSES, EXPENSES, DAMAGES AND COSTS (INCLUDING, BUT NOT LIMITED TO, DIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY AND INDIRECT DAMAGES), AND REASONABLE ATTORNEYS’ FEES, RESULTING FROM OR ARISING OUT OF YOUR USE, MISUSE, OR INABILITY TO USE THE SITE, THE SERVICE, OR THE CONTENT, OR ANY VIOLATION BY YOU OF THIS AGREEMENT, EVEN IF RESULTING, IN WHOLE OR IN PART, FROM THE NEGLIGENCE, GROSS NEGLIGENCE, WILLFUL MISCONDUCT, STRICT LIABILITY OR OTHER LEGAL FAULT OF ANY OF THE INDEMNIFIED PARTIES.<br></br><br></br>
                                    
                                    <strong>17. Privacy</strong><br></br><br />
                                    Information submitted is subject to the Privacy Policy stated on the Lucky Rewards .com Site. To read the Privacy Policy, <a href="/privacy-policy" >click here.</a><br></br><br></br>
                                    
                                    <strong>18. Player Information</strong><br></br><br />
                                    Lucky Rewards does not want to receive confidential or proprietary information from you via the Site. You agree that any material, information, or data you transmit to us or post to the Site (each a Submission or collectively Submissions) will be considered non-confidential and non-proprietary. For all Submissions, (1) you guarantee to us that you have the legal right to provide or post the Submission and that it will not violate any law or the rights of any person or entity, (2) you give Lucky Rewards  the non-exclusive, royalty-free, irrevocable, perpetual, worldwide right to use, distribute, display and create derivative works from the Submission, in any and all media, in any manner, in whole or in part, without any restriction or responsibilities to you, and (3) you waive all moral rights in and to all Submissions in favour of Lucky Rewards . Note, any banking or credit card information you supply to Lucky Rewards will be held in a confidential manner and not shared with third parties except to process any billing or pay-outs.<br></br><br></br>
                                
                                    <strong>19. Password Security</strong><br></br><br />
                                    If you register for an account on the Site, you are responsible for maintaining the confidentiality of your player identification and password information, and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your player identification and password.<br></br><br></br>
                                    
                                    <strong>20. Miscellaneous / General</strong><br></br><br />
                                    Lucky Rewards reserves the right to modify these Conditions if it is reasonably necessary to do so.  Any changes will be notified on the Site. If it is reasonably necessary to do so Lucky Rewards reserves the right to withdraw the Site or any of the Services without prior notice.
                                    Player Conduct. You agree to use the Site only for lawful purposes. You agree not to take any action that might compromise the security of the Site, render the Site or the Service inaccessible to others or otherwise cause damage to the Site, the Service, or the content. You agree not to add to, subtract from, or otherwise modify the content, or to attempt to access any content that is not intended for you. You agree not to use the Site in any manner that might interfere with the rights of third parties.
                                    Entire Agreement. These Conditions represent the entire agreement between the parties relating to their subject matter and supersede all such prior agreements, arrangements, and undertakings between the parties.  Players have no remedy in respect of any untrue statement innocently or negligently made by or on behalf of Lucky Rewards  prior to entering into these Conditions which they relied upon in entering into these Conditions whether such representation was made orally or in writing.
                                    No Waiver. No waiver by Lucky Rewards of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.<br></br>
                                    Correction of Errors and Inaccuracies. The content may contain typographical errors or other errors or inaccuracies and may not be complete or current. Lucky Rewards therefore reserves the right to correct any errors, inaccuracies, or omissions and to change or update the content at any time without prior notice. Lucky Rewards does not, however, guarantee that any errors, inaccuracies, or omissions will be corrected.
                                    No Assignment. These Conditions are personal to the player and cannot be assigned or transferred to any other person.  Players may not sub-contract any of their obligations under these Conditions.  Lucky Rewards may assign, transfer or subcontract the performance of its rights or obligations under these Conditions.
                                    Non-Variation. No variation of these Conditions will be valid unless authorised in writing by one of Lucky Rewards ’s duly authorised representatives.<br></br>
                                    Relationship of Parties. Nothing in these Conditions shall constitute or be construed as constituting a partnership or joint venture between Lucky Rewards and a player; or shall authorise either party to enter into contractual relationships or incur obligations on behalf of the other.
                                    Enforcement by Third Party. Except insofar as these Conditions expressly provide that a third party may in their own right enforce a term contained in these Conditions, a person or entity who is not a party to these Conditions has no right under local law or statute to rely upon or enforce any term of these Conditions. The aforementioned does not affect any right or remedy of a third party which exists or is available apart from under that local law or statute.
                                    Enforcement/ Choice of Law/ Choice of Forum. If any part of this Agreement is determined by a court of competent jurisdiction to be invalid or unenforceable, it will not impact any other provision of this Agreement, all of which will remain in full force and effect. Any and all disputes relating to this Agreement, Lucky Rewards ’s Privacy Policy, your use of the Site, any other Lucky Rewards  web site, the Service, or the Content shall be governed by, and will be interpreted in accordance with, the laws of Cyprus in the English language, without regard to any conflict of laws provisions. Players agree to submit to the exclusive jurisdiction of the Cypriot courts.
                                     These Conditions are subject to change anytime, for any reason, with or without prior or subsequent notification.
                                    Conditions Last Revised: 28th July 2020.<br></br>
                                    </p>
              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
    );
}

export default TermsConditions;                