import React, {useEffect, useState} from "react";
import axios from 'axios';
import {getConfig} from "../config";

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ProfileComponent = () => {

  const { apiOrigin = "https://api.luckyrewards.co" } = getConfig();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [msisdn, setMsisdn] = useState("")
  const [id_number, setIdNumber] = useState("")

  useEffect(() => {
    (async () => {
      await getUser();
      setLoading(false)
    })()
}, []);

  async function getUser() {

    const token = await getAccessTokenSilently();

    console.log(token)

    const response = await fetch(`${apiOrigin}/users/id`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    const responseData = await response.json();

    setName(responseData[0].name)
    setSurname(responseData[0].surname)
    setMsisdn(responseData[0].msisdn)
    setIdNumber(responseData[0].id_number)

    return
  }

  const handleNameChange = (e) => { setName(e.target.value)}
  const handleSurnameChange = (e) => { setSurname(e.target.value) }
  const handleMsisdnChange = (e) => { setMsisdn(e.target.value)}
  const handleIDChange = (e) => { setIdNumber(e.target.value)}

  const submitProfileUpdates = async () => {

    let token = await getAccessTokenSilently()
    let response = await axios.put(`${apiOrigin}/users/id`, {
        msisdn: msisdn,
        name: name,
        surname: surname,
        id_number: id_number

      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })

    if(response.status == 200 && response.statusText == "OK") { await getUser() } 

  }

  if(loading) return <Loading/>

  return (
  <section className="position-relative pt-120 pb-120 background-blue-contest contact">
    <div class="container">
      <div class="row justify-content-center pt-120">
        <div class="col-lg-12">
          <div class="contact-wrapper">
            <div class="row">
              <div class="col-lg-12">
                <div class="contact-form-wrapper">
                  <h3 class="title">Update your profile</h3>
                  <div class="contact-form" id="contact_form_submit">
                    <div class="form-group">
                      <label>Name <sup>*</sup></label>
                      <input type="text" name="name" value={name} placeholder="Enter Your First Names" onChange={handleNameChange} />
                    </div>
                    <div class="form-group">
                      <label>Surname <sup>*</sup></label>
                      <input type="text" name="surname" value={surname} placeholder="Enter Your Surname" onChange={handleSurnameChange} />
                    </div>
                    <div class="form-group">
                      <label>Mobile Number <sup>*</sup></label>
                      <input type="text" name="msisdn"  value={msisdn} placeholder="Enter Your Mobile Number" onChange={handleMsisdnChange} />
                    </div>
                    <div class="form-group">
                      <label>ID Number <sup>*</sup></label>
                      <input type="text" name="id_number" value={id_number} placeholder="Enter Your ID Number" onChange={handleIDChange} />
                    </div>
                    <div class="form-group">
                      <button type="submit" class="cmn-btn w-100" onClick={() => submitProfileUpdates()}>Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </section>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
