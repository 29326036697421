import React from "react";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Loading from "../components/Loading";
import moment from "moment";

export const MyEntries = () => {

  return (
    <section className="position-relative pt-120 pb-120 background-blue-contest">
      <div className="container pt-120">
          <div className="row justify-content-center">
              <div className="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                  <div className="section-header text-center">
                      <h2 className="section-title section-title-white">VIP Area</h2>
                      <p className="section-sub-title">Your exclusive VIP Area. As part of your VIP rewards, you get:</p>
                      <ul>
                          <li>1. Access to the VIP Area where you can play additional games</li>
                          <li>2. Automatic lucky reward number generation everyday if you forget to play</li>
                          <li>3. We will call and email you if you have winning numbers, no need to come back and check</li>
                      </ul>
                  </div>
              </div>
          </div>

          <div className="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
              <div className="col-lg-12">
                  <div className="tab-content" id="contestTabContent">
                      <div className="tab-pane fade show active" id="car" role="tabpanel" aria-labelledby="car-tab">
                          <div className="row mb-none-30">
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/bubble-shooter-challenge/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/bubble-shooter-challenge/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/jewels-blitz-challenge/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/jewels-blitz-challenge/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/solitaire-garden/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/solitaire-garden/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/solitaire-mahjong/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/solitaire-mahjong/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/garden-tales/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/garden-tales/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/garden-tales-2/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/garden-tales-2/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/jewels-blitz-4/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/jewels-blitz-4/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/solitaire-garden/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/solitaire-garden/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/forest-match/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/forest-match/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/candy-rain-6/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/candy-rain-6/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/fish-story/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/fish-story/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="https://games.softgames.com/games/jungle-match/gamesites/4947/" target="_blank" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src="https://softgames-kirk-assets.sgweb.softgames.de/assets/games/jungle-match/teaser.jpg?p=pub-12499-12499" alt="image"/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

    </section>
  );
};

export default withAuthenticationRequired(MyEntries, {
  onRedirecting: () => <Loading />,
});
