
import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import OneSignal from 'react-onesignal';

import TagManager from 'react-gtm-module'

import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./views/Home";
import Results from "./views/Results";
import FAQs from "./views/FAQs";
import Contact from "./views/Contact";
import Profile from "./views/Profile";
import MyEntries from "./views/MyEntries";
import VIP from "./views/VIP";
import VIPArea from "./views/VIPArea";
import Games from "./views/Games";

import Terms from "./views/Terms";
import Privacy from "./views/Privacy";

import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import './assets/css/vendor/bootstrap.min.css'
import './assets/css/all.min.css'
import './assets/css/line-awesome.min.css'
import './assets/css/vendor/nice-select.css'
import './assets/css/vendor/animate.min.css'
import './assets/css/vendor/lightcase.css'
import './assets/css/vendor/slick.css'
import './assets/css/vendor/datepicker.min.css'
import './assets/css/main.css'

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {

  const tagManagerArgs = {
    gtmId: 'UA-54398425-16'
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    
    OneSignal.init({
      appId: "d1e541ab-ee01-4ea9-9fc0-39685e0e849c"
    });
  }, []);

  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
      <div className="page-wrapper">
        <Router history={history}>
          <Header/>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/results" exact component={Results} />
            <Route path="/faqs" exact component={FAQs} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/my-entries" component={MyEntries} />
            <Route path="/profile" component={Profile} />
            <Route path="/vip" component={VIP} />
            <Route path="/vip-area" component={VIPArea} />
            <Route path="/game" exact component={Games} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/privacy" exact component={Privacy} />
          </Switch>
          <Footer/>
        </Router>
      </div>
  );
};

export default App;
