import React, { useState, useEffect } from 'react';
import { Accordion, Card } from "react-bootstrap";
import moment from "moment";

const FAQs = () => {

    const [loading, setLoading] = useState(true)
    const [faqs, setFaqs] = useState([])

    useEffect(() => {
        (async () => {
            setFaqs([
                {
                title: "How do I play these free competition games?",
                body: "In order to play Lucky Rewards , you first need to register here as a player. Once you've done so, you'll have access to up to 14 free competition games daily. To play the games, start with Game 1 and choose 6 numbers in the play block in order to enter for the R10 000 cash prize. Once you're satisfied with your choice, click 'Confirm Entry'. You'll then be able to move onto the rest of the free competition games and play each in the same way - with not one, but TWO free entries into our daily R12,000,000 Grand Prize Draw! Remember that in order to take part, you need to play ALL smaller competition games first - then check back the next day to claim your prize if you're a winner!"
                },
                {
                title: "How are the winning numbers chosen?",
                body: "In order to ensure 100% game fairness, we make use of an outsourced, certified Random Number Generator (RNG) that selects 6 numbers from each play board at 19:00 CET every day. These results are then immediately uploaded to the Results/My Entries page, where you can check the winning numbers against your own daily picks."
                },
                {
                title: "Where are your offices?",
                body: "Lucky Rewards is based in Cyprus. Our full office address is as follows: 7th floor, 2-4 Capital Center, Nicosia. We also maintain satellite offices around the world. As home to some of the world's largest gaming companies, with a focus on European standards of infrastructure and robust and transparent legislation pertaining to competition-based gaming operators, Cyprus is the ideal base of operations for LuckyRewards.com."
                },
                {
                title: "Am I allowed to play competition style lottery games?",
                body: "Yes. As Lucky Rewards offers free competition games without taking any entry fees or portions of any prizes, we are therefore not considered to be a gambling operator. As such, sweepstakes are usually deemed legal in most countries."
                },
                {
                title: "How are my winnings paid out?",
                body: "If you're one of our lucky winners, then we want to get your prize to you as soon as we can! To do so, we ensure that all winnings are paid out within 48 hours of verification via cheque or bank to bank transfer*. Alternatively, if you prefer, we can process your payout via Skrill or Neteller as well. *Please note that international bank transfers incur a sending fee as imposed by the bank and payable out of a player’s winnings. This international bank fee can be up to $30 USD."
                },
                {
                title: "Are you a legal competitions company?",
                body: "Yes. We've been in the gaming business for over 10 years now and have operated many online lotto platforms and similar gaming competitions in that time. We've also managed to keep our many customers satisfied and maintain a 100% payout record since our inception - a record we're immensely proud of."
                },
                {
                title: "How do I claim my winnings?",
                body: "Winning numbers are announced on a daily basis and can be verified on the LucyRewards.com website. For winning numbers, check the My Entries/Results page and then double-check your own number picks from within the My Account section of the website. If you believe you may have won* one of our free competition games, simply send an email to info@luckyrewards.com confirming the name of the competition game, the date of the game played and your matching numbers. *Please note that players who deliberately attempt to mislead or make false winning claims will have their accounts closed immediately. Managing false claims simply increases our overall costs which in turn reduces the jackpots that we can legitimately offer to our genuine players. Please make sure that the date of your winning claim is accurate before emailing our team. Alternatively, you can subscribe to our Lucky Rewards premium membership here and ensure that your winnings on ALL 14 daily games (including 2 x jackpot games) are automatically validated and paid out without you ever needing to check."
                },
                {
                title: "Why am I not receiving emails?",
                body: "You can rest assured that your confirmation emails are being sent - the reason you may not be seeing them could be due to your email provider (Gmail, Yahoo, Vodacom, etc) flagging them as spam. This is usually due to words such as “lottery”, “winnings”, “free”, and “jackpot” being blacklisted by email servers. Should this be the case, you will most likely find your confirmation emails in your Spam or Trash folders."
                },
                {
                title: "How can I cancel my subscription?",
                body: "If you would like to cancel your subscription, you can do so quickly and easily at any time. Simply login to your account, click on the 'Subscription' tab underneath the 'My Account' link on the website header, and tick the 'Stop Subscription' box. Your recurring subscription will be cancelled immediately, and no further debits will be made to your account. Please note: If you are unable to cancel your subscription in this way, we will also be able to cancel your subscription for you. In order for us to do so, simply email the details of the subscription you wish to cancel to info@luckyrewards.com and we will attend to your request within 24 hours of receiving your email. NB: In order to ensure that your account is not debited for the upcoming month, you will need to cancel your subscription no later than two days before the end of the calendar month."
                },
                {
                title: "Why I am unable to play at luckyrewards.com?",
                body: "In order to play at luckyrewards.com, you must first have registered an account with us. If you have done so, and are still unable to play, you may have inadvertently made yourself ineligible for one of the following reasons: You may have requested that your ISP (internet service provider) block all emails from luckyrewards.com, which has in turn unsubscribed you from our email list: <ul><li>You may have clicked on the link stating: “If you'd prefer not to receive luckyrewards.com e-mails please click below to unsubscribe:”, which would have also unsubscribed you from our email list;</li> <li>You may have asked the luckyrewards.com.com customer service team to unsubscribe you from our email list;</li> <li>Or you may have clicked on the “Confirm Unsubscribe” button within your luckyrewards.com.com player profile, unsubscribing you from receiving any luckyrewards.com emails.</li></ul>"
                },
                {
                title: "I have received a winning notification. Is it a scam?",
                body: "Unfortunately, it might be - many customers do receive fake winning notifications from companies claiming to be online ticket vendors. If you are unsure of the origin of any competition lottery related mailer you may have received, please contact us for verification, and for your own safety."
                },
                {
                title: "How secure are your transactions?",
                body: "As an EU based E-commerce Company, our online store has to comply with stringent data protection measures at all times. We use Thawte SSL 256 bit security encryption and our credit/ debit card payments are processed through one of the UK’s leading merchant services. We are also fully PCI Compliant (Payment Card industry Compliant) and attend to quarterly security scanning as required by PCI legislation to ensure your payments are always processed securely. Please go to our Privacy Policy to view further measures which we take to ensure your browsing remains safe and secure at all times."
                },
                {
                title: "How many numbers do I need to match in order to win a prize?",
                body: "You will need to match six numbers correctly per line for a winning payout. Please click on the question below in order to see what cash prizes are available per game."
                },
                {
                title: "What type of prizes are available in the free competitions?",
                body: "Game 1: R10 000 Grand Prize Match 6 of 6 numbers, <br /> Game 2: R20 000 Grand Prize Match 6 of 6 numbers, <br /> Game 3: R40 000 Grand Prize Match 6 of 6 numbers, <br />Game 4: R60 000 Grand Prize Match 6 of 6 numbers, <br />Game 5: R80 000 Grand Prize Match 6 of 6 numbers, <br />Game 6: R100 000 Grand Prize Match 6 of 6 numbers, <br />Game 7: R12 000 000 Grand Prize Match 6 of 6 numbers"
                },
                {
                title: "How will I know if I have won?",
                body: "If you play our competition lottery style games for free you have to check the results yourself and claim the prize within 5 days. If you are a subscriber, we will send you WINNING NOTIFICATION via email as well as phone you!"
                },
                {
                title: "Do I pay tax on my competition prize?",
                body: "Lucky Rewards will transfer the full prize amount (less bank transfer fees incurred) to the player as it is mentioned on the site. It is the players own responsibility to declare a windfall with their residing country’s tax authorities."
                },
                {
                title: "Do you have any type of anti-money laundering policy?",
                body: "A: As part of our commitment to protecting your funds at all times, Lucky Rewards works according to certain anti-money laundering laws and regulations when processing player withdrawals and payouts. To this end, you may be required to submit certain documentation to our accounts department before your withdrawal can be processed. This includes:<br></br><br></br>1. Proof of Identity: A full colour scan of your passport, driver’s license, or government-issued ID card.<br></br><br></br>2. Proof of Address: A valid utility bill such as water, electricity, or gas, not more than 3 months old.<br></br><br></br>Once you have submitted the required documentation, our accounts department will verify it to ensure that it is relevant and valid according to international standards. For more information on withdrawals and anti-fraud controls, simply contact your dedicated account manager or email our support team on info@luckyrewards.com"
                },
                {
                title: "Until when can I change my numbers for a valid draw?",
                body: "Any numbers changed before 8am CET will be valid and entered for today's jackpot draw. Should you miss the cut off time and change your numbers after 8am CET, then your new selected numbers will only be entered into the following days draw."
                }
            ])
            setLoading(false)
        })()
    },[])

    return (
      <section className="position-relative pt-120 pb-120 background-blue-contest">
          <div className="container pt-120">
              <div className="row justify-content-center">
                  <div className="col-lg-10">
                      <div className="section-header text-center">
                          <h2 className="section-title">We have answers</h2>
                          <p>Our FAQs provide answers to our most asked questions, if you have any other questions, please fill in the form on the Contact page.</p>
                      </div>
                  </div>
              </div>
              <div class="row justify-content-center">
                  <div class="col-lg-10">
                      <div class="faq-body-wrapper">
                          <div class="accordion cmn-accordion" id="faqAcc-one">
                              <Accordion className="">
                                  {faqs.map((item, index)=>
                                      <Card>
                                          <Accordion.Toggle as={Card.Header} eventKey={index+1}>
                                              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                  {item.title}
                                              </button>
                                          </Accordion.Toggle>

                                          <Accordion.Collapse eventKey={index+1}>
                                              <Card.Body><p>{item.body}</p></Card.Body>
                                          </Accordion.Collapse>
                                      </Card>
                                  )}
                              </Accordion>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    );

}

export default FAQs;
