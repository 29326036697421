import React from 'react'

const SecondsCountDownTimer = ({hoursMinSecs}) => {
   
    const { hours = 0, minutes = 0, seconds = 10 } = hoursMinSecs;
    const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);
    

    const tick = () => {
   
        if (hrs === 0 && mins === 0 && secs === 0) {

        } else if (mins === 0 && secs === 0) {
            setTime([hrs - 1, 59, 9]);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 9]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }
    };


    const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

    
    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    
    return (
        secs != 0 && <p>{`${secs.toString().padStart(2, '0')}`}</p> 
        
    );
}

export default SecondsCountDownTimer;