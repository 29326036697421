import React, {useEffect, useState} from "react";

import hero_image from "../assets/images/elements/hero.png";
import hero_start from "../assets/images/elements/hero-star.png";
import {getConfig} from "../config";
import {useAuth0} from "@auth0/auth0-react";

const Hero = () => {

    const {apiOrigin = "https://api.luckyrewards.co"} = getConfig();

    const {
        isAuthenticated,
        getAccessTokenSilently,
    } = useAuth0();

    const [state, setState] = useState({
        user: [],
        first_name: '',
        lastname: '',
        id_number: '',
        msisdn: '',
        subscribed_date: '',
        unsubscribed_date: '',
        isSubscribed: false,
        loading: true
    });

    useEffect(() => {
        (async () => {
            await getUser();
        })()
    }, [isAuthenticated]);

    const getUser = async () => {

        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${apiOrigin}/users/id`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const responseData = await response.json();
            const subscribedDate = responseData[0].subscribed_date
            const unSubscribedDate = responseData[0].unsubscribed_date
            let isSubscribed = false
            if (subscribedDate && !unSubscribedDate) {
                isSubscribed = true
            }

            setState({
                ...state,
                user: responseData[0],
                first_name: responseData[0].name,
                last_name: responseData[0].surname,
                msisdn: responseData[0].msisdn,
                id_number: responseData[0].id_number,
                subscribed_date: subscribedDate,
                unsubscribed_date: unSubscribedDate,
                isSubscribed: isSubscribed,
                loading: false
            });
        }
    }

    return (
        <section className="hero background-blue-hero">
            <div className="hero__car">
                <img src={hero_image} alt="image" className="hero-car"/>
                <img src={hero_start} alt="image" className="car-star"/>
            </div>
            <div className="container">
                <div className="row justify-content-center justify-content-lg-start">
                    <div className="col-lg-6 col-md-8">
                        <div className="hero__content">
                            <div className="hero__subtitle">Play for <span>free</span></div>
                            <h2 className="hero__title">WIN BIG</h2>
                            <p>Now's your chance to win! Will you be our next lucky rewards winner? Play for free every day and stand a chance to win some amazing prizes. You could even win <span>R1,000,000</span> in cash.</p>
                            <div className="hero__btn">
                                <a href="/game" className="cmn-btn">Play Now</a>
                                {!state.loading && !state.isSubscribed && (
                                    <a href="/vip" className="cmn-btn style--two">Join VIP</a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Hero;
