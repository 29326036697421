import React, {useEffect, useState} from "react";
import moment from "moment";

import prize_9 from "../assets/images/prizes/game_9.jpg";
import prize_8 from "../assets/images/prizes/game_8.jpg";
import prize_7 from "../assets/images/prizes/game_7.jpg";
import prize_6 from "../assets/images/prizes/game_6.jpg";
import prize_5 from "../assets/images/prizes/game_5.jpg";
import prize_4 from "../assets/images/prizes/game_4.jpg";
import prize_3 from "../assets/images/prizes/game_3.jpg";
import prize_2 from "../assets/images/prizes/game_2.jpg";
import prize_1 from "../assets/images/prizes/game_1.jpg";

import {getConfig} from "../config";
import CountDownTimer from "./CountDownTimer";

export const Prizes = () => {

    const {apiOrigin = "https://api.luckyrewards.co"} = getConfig();

    const [state, setState] = useState({
        showResult: false,
        latest_results_date: null,
        rounds: null,
        error: null
    });

    useEffect(() => {
        (async () => {
            await getLatestResults();
        })()
    }, []);

    const getLatestResults = async () => {

        console.log("Get Latest Results")

        try {

            const response = await fetch(`${apiOrigin}/draws/latest`, {});
            const responseData = await response.json();

            console.log(responseData)

            let draw_date = responseData[0].draw_date

            
            let game_1_numbers = responseData[0].rounds[0].draw_games[0] ? responseData[0].rounds[0].draw_games[0].numbers.split(",") : []
            let game_2_numbers = responseData[0].rounds[0].draw_games[1] ? responseData[0].rounds[0].draw_games[1].numbers.split(",") : []
            let game_3_numbers = responseData[0].rounds[0].draw_games[2] ? responseData[0].rounds[0].draw_games[2].numbers.split(",") : []
            let game_4_numbers = responseData[0].rounds[0].draw_games[3] ? responseData[0].rounds[0].draw_games[3].numbers.split(",") : []
            let game_5_numbers = responseData[0].rounds[0].draw_games[4] ? responseData[0].rounds[0].draw_games[4].numbers.split(",") : []
            let game_6_numbers = responseData[0].rounds[0].draw_games[5] ? responseData[0].rounds[0].draw_games[5].numbers.split(",") : []
            let game_7_numbers = responseData[0].rounds[0].draw_games[6] ? responseData[0].rounds[0].draw_games[6].numbers.split(",") : []
            let game_8_numbers = responseData[0].rounds[0].draw_games[7] ? responseData[0].rounds[0].draw_games[7].numbers.split(",") : []
            let game_9_numbers = responseData[0].rounds[0].draw_games[8] ? responseData[0].rounds[0].draw_games[8].numbers.split(",") : []
        

            let rounds = {
                game_1_numbers,
                game_2_numbers,
                game_3_numbers,
                game_4_numbers,
                game_5_numbers,
                game_6_numbers,
                game_7_numbers,
                game_8_numbers,
                game_9_numbers,
            }

            console.log(rounds)
            setState({
                ...state,
                showResult: true,
                latest_results_date: draw_date,
                rounds: rounds
            });

        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    function pad(num) {
        return ("0" + parseInt(num)).substr(-2);
    }

    var start = new Date;
    start.setHours(20, 0, 0); // 7pm
    var now = new Date;

    if (now > start) { // too late, go to tomorrow
        start.setDate(start.getDate() + 1);
    }

    var remain = ((start - now) / 1000);
    var hh = pad((remain / 60 / 60) % 60);
    var mm = pad((remain / 60) % 60);
    var ss = pad(remain % 60);
    
    const hoursMinSecs = {hours:hh, minutes: mm, seconds: ss}

    return (
        <section className="position-relative pt-120 pb-120 background-blue-contest">
            <div className="container ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                        <div className="section-header text-center">
                            <h2 className="section-title section-title-white">Winning Numbers</h2>
                            <p>Win BIG with our latest prizes on offer. Check each prize to see the latest results drawn.</p>
                            <CountDownTimer hoursMinSecs={hoursMinSecs}/>
                        </div>
                    </div>
                </div>
                <div className="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div className="col-lg-12">
                        <div className="tab-content" id="contestTabContent">
                            <div className="tab-pane fade show active" id="car" role="tabpanel" aria-labelledby="car-tab">
                                <div className="row mb-none-30">
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_1} alt="image"/>
                                            </div>

                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_1_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_1_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_2} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_2_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_2_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_3} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_3_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_3_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_4} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_4_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_4_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_5} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_5_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_5_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_6} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_6_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_6_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_7} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_7_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_7_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_8} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_8_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_8_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 mb-30">
                                        <div className="contest-card">
                                            <div className="contest-card__thumb">
                                                <img src={prize_9} alt="image"/>
                                            </div>
                                            <div className="contest-card__footer">
                                                <p className="contest-card__name__right">Winning Numbers</p>
                                                <div className="number-list-wrapper">
                                                    {state.rounds && state.rounds.game_9_numbers.length != 0 ? 
                                                    <ul className="number-draw-list">
                                                        {state.rounds && (
                                                            state.rounds.game_9_numbers.map((number) =>
                                                                <li>{number}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                    :
                                                    <p>No Results</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Prizes;
