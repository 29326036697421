import React, {useEffect, useState, Alert} from "react";
import {getConfig} from "../config";
import moment from 'moment';
import Select from 'react-select';

import prize_9 from "../assets/images/prizes/game_9.jpg";
import prize_8 from "../assets/images/prizes/game_8.jpg";
import prize_7 from "../assets/images/prizes/game_7.jpg";
import prize_6 from "../assets/images/prizes/game_6.jpg";
import prize_5 from "../assets/images/prizes/game_5.jpg";
import prize_4 from "../assets/images/prizes/game_4.jpg";
import prize_3 from "../assets/images/prizes/game_3.jpg";
import prize_2 from "../assets/images/prizes/game_2.jpg";
import prize_1 from "../assets/images/prizes/game_1.jpg";


import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Loading from "../components/Loading";

export const MyEntries = () => {
  const { apiOrigin = "https://api.luckyrewards.co" } = getConfig();

  const [loading, setLoading] = useState(true)
  const [state, setState] = useState({
    showResult: false,
    results: [],
    rounds: "",
    draws: "",
    select_options: "",
    selected_date: "",
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#142066' : '',
    })
  }

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  useEffect(() => {
    (async () => {
      await getUserEntries();
      setLoading(false)
    })()
  },[]);

  const handleDateSelect = (selections) => {

    let selected_option = null
    if(!!selections) selected_option = selections

    let played_date = state.results[selected_option.value].date
    let selected_date = {value: selected_option.value, label: moment(played_date).format('DD MMM YYYY')}    

    let game_1_numbers = (typeof state.results[selected_option.value].entries[0].games[0]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[0].numbers.split(",") : []
    let game_2_numbers = (typeof state.results[selected_option.value].entries[0].games[1]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[1].numbers.split(",") : []
    let game_3_numbers = (typeof state.results[selected_option.value].entries[0].games[2]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[2].numbers.split(",") : []
    let game_4_numbers = (typeof state.results[selected_option.value].entries[0].games[3]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[3].numbers.split(",") : []
    let game_5_numbers = (typeof state.results[selected_option.value].entries[0].games[4]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[4].numbers.split(",") : []
    let game_6_numbers = (typeof state.results[selected_option.value].entries[0].games[5]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[5].numbers.split(",") : []
    let game_7_numbers = (typeof state.results[selected_option.value].entries[0].games[6]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[6].numbers.split(",") : []
    let game_8_numbers = (typeof state.results[selected_option.value].entries[0].games[7]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[7].numbers.split(",") : []
    let game_9_numbers = (typeof state.results[selected_option.value].entries[0].games[8]  != 'undefined' ) ? state.results[selected_option.value].entries[0].games[8].numbers.split(",") : []
        
    let rounds = {
        game_1_numbers,
        game_2_numbers,
        game_3_numbers,
        game_4_numbers,
        game_5_numbers,
        game_6_numbers,
        game_7_numbers,
        game_8_numbers,
        game_9_numbers,
    }
    
    let draw_1_numbers =  []
    let draw_2_numbers =  []
    let draw_3_numbers =  []
    let draw_4_numbers =  []
    let draw_5_numbers =  []
    let draw_6_numbers =  []
    let draw_7_numbers =  []
    let draw_8_numbers =  []
    let draw_9_numbers =  []

    if(state.results[selected_option.value].draw.length != 0 ) {
      draw_1_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[0]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[0].numbers.split(",") : []
      draw_2_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[1]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[1].numbers.split(",") : []
      draw_3_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[2]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[2].numbers.split(",") : []
      draw_4_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[3]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[3].numbers.split(",") : []
      draw_5_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[4]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[4].numbers.split(",") : []
      draw_6_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[5]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[5].numbers.split(",") : []
      draw_7_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[6]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[6].numbers.split(",") : []
      draw_8_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[7]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[7].numbers.split(",") : []
      draw_9_numbers = (typeof state.results[selected_option.value].draw[0].draw_games[8]  != 'undefined' )  ? state.results[selected_option.value].draw[0].draw_games[8].numbers.split(",") : []
    }

    let draws = {
      draw_1_numbers,
      draw_2_numbers,
      draw_3_numbers,
      draw_4_numbers,
      draw_5_numbers,
      draw_6_numbers,
      draw_7_numbers,
      draw_8_numbers,
      draw_9_numbers,
  }
  

    setState({
        ...state,
        rounds: rounds,
        draws: draws,
        selected_date: selected_date,
    });
}

  const handleConsent = async () => {

    console.log("Handle Consent")

    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await getUserEntries();
  };

  const handleLoginAgain = async () => {

    console.log("Handle Login")

    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await getUserEntries();
  };

  const getUserEntries = async () => {

    try {
      const token = await getAccessTokenSilently();
      console.log(token)
      const response = await fetch(`${apiOrigin}/users/id/history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      const responseData = await response.json();

      console.log('responseData', responseData)

      if(responseData && responseData.length != 0 ) {
      
        let select_options = []
        for (let d = 0; d < responseData.length; d++) {
            const option = responseData[d];
            select_options.push({value: d, label: moment(option.date).format('DD MMM YYYY')});
        }

        let last_result = responseData.length-1
        let played_date = responseData[last_result].date

        let selected_date = {value: last_result, label: moment(played_date).format('DD MMM YYYY')}
        
        let game_1_numbers = (typeof responseData[last_result].entries[0].games[0]  != 'undefined' ) ? responseData[last_result].entries[0].games[0].numbers.split(",") : []
        let game_2_numbers = (typeof responseData[last_result].entries[0].games[1]  != 'undefined' ) ? responseData[last_result].entries[0].games[1].numbers.split(",") : []
        let game_3_numbers = (typeof responseData[last_result].entries[0].games[2]  != 'undefined' ) ? responseData[last_result].entries[0].games[2].numbers.split(",") : []
        let game_4_numbers = (typeof responseData[last_result].entries[0].games[3]  != 'undefined' ) ? responseData[last_result].entries[0].games[3].numbers.split(",") : []
        let game_5_numbers = (typeof responseData[last_result].entries[0].games[4]  != 'undefined' ) ? responseData[last_result].entries[0].games[4].numbers.split(",") : []
        let game_6_numbers = (typeof responseData[last_result].entries[0].games[5]  != 'undefined' ) ? responseData[last_result].entries[0].games[5].numbers.split(",") : []
        let game_7_numbers = (typeof responseData[last_result].entries[0].games[6]  != 'undefined' ) ? responseData[last_result].entries[0].games[6].numbers.split(",") : []
        let game_8_numbers = (typeof responseData[last_result].entries[0].games[7]  != 'undefined' ) ? responseData[last_result].entries[0].games[7].numbers.split(",") : []
        let game_9_numbers = (typeof responseData[last_result].entries[0].games[8]  != 'undefined' ) ? responseData[last_result].entries[0].games[8].numbers.split(",") : []

        console.log('AFTER GAMES')      

        let rounds = {
            game_1_numbers,
            game_2_numbers,
            game_3_numbers,
            game_4_numbers,
            game_5_numbers,
            game_6_numbers,
            game_7_numbers,
            game_8_numbers,
            game_9_numbers,
        }

        let draw_1_numbers =  []
        let draw_2_numbers =  []
        let draw_3_numbers =  []
        let draw_4_numbers =  []
        let draw_5_numbers =  []
        let draw_6_numbers =  []
        let draw_7_numbers =  []
        let draw_8_numbers =  []
        let draw_9_numbers =  []
        
        if(responseData[last_result].draw.length != 0) {
          draw_1_numbers = (typeof responseData[last_result].draw[0].draw_games[0]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[0].numbers.split(",") : []
          draw_2_numbers = (typeof responseData[last_result].draw[0].draw_games[1]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[1].numbers.split(",") : []
          draw_3_numbers = (typeof responseData[last_result].draw[0].draw_games[2]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[2].numbers.split(",") : []
          draw_4_numbers = (typeof responseData[last_result].draw[0].draw_games[3]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[3].numbers.split(",") : []
          draw_5_numbers = (typeof responseData[last_result].draw[0].draw_games[4]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[4].numbers.split(",") : []
          draw_6_numbers = (typeof responseData[last_result].draw[0].draw_games[5]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[5].numbers.split(",") : []
          draw_7_numbers = (typeof responseData[last_result].draw[0].draw_games[6]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[6].numbers.split(",") : []
          draw_8_numbers = (typeof responseData[last_result].draw[0].draw_games[7]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[7].numbers.split(",") : []
          draw_9_numbers = (typeof responseData[last_result].draw[0].draw_games[8]  != 'undefined' )  ? responseData[last_result].draw[0].draw_games[8].numbers.split(",") : []
        } 


        console.log('AFTER DRAWS') 

        let draws = {
          draw_1_numbers,
          draw_2_numbers,
          draw_3_numbers,
          draw_4_numbers,
          draw_5_numbers,
          draw_6_numbers,
          draw_7_numbers,
          draw_8_numbers,
          draw_9_numbers,
      }

        setState({
          ...state,
          showResult: true,
          results: responseData,
          rounds: rounds,
          draws: draws,
          select_options: select_options,
          selected_date: selected_date,
      });

      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };


  if(loading) return <Loading/>

  return (
    <>
    <section className="position-relative pt-120 pb-120 background-blue-contest">
      <div className="container pt-120">
          
          <div className="row justify-content-center">
              <div className="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                  <div className="section-header text-center">
                      <h2 className="section-title">My Entries</h2>
                      {state.results.length != 0 ?
                      <p className="section-sub-title">Showing entries for {state.selected_date.label} </p>
                      : 
                      <>
                      <p className="section-sub-title">No entries to show</p>
                      <p className="section-sub-title">Play now to stand a chance to win!</p>
                      <div className="hero__btn">
                        <a href="/game" className="cmn-btn">Play Now</a>
                      </div>
                      </>
                      }
                  </div>
              </div>
          </div>

        {state.select_options &&
        <div className="row justify-content-center text-center mb-30">
            <div className="col-lg-8">
                <p className="mb-4 white">Select the date you want to see the entries for:</p>
                <Select options={state.select_options} styles={customStyles} onChange={handleDateSelect} value={state.selected_date} defaultValue={state.selected_date} />
            </div>
        </div>
        }

        {state.results.length != 0 &&
          <div className="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
              <div className="col-lg-12">
                  <div className="tab-content" id="contestTabContent">
                      <div className="tab-pane fade show active" id="car" role="tabpanel" aria-labelledby="car-tab">
                          <div className="row mb-none-30">
                            {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_1} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_1_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.rounds.game_1_numbers.length != 0 ?
                                                <ul className="number-list">
                                                    {state.rounds.game_1_numbers.map((number) => <li>{number}</li>)}
                                                </ul>
                                              :
                                                <p>No Entry Found</p>
                                              }
                                          </div>
                                          }
                                          <p className="contest-card__name__right">Draw Result</p>
                                          {state.draws && state.draws.draw_1_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.draws.draw_1_numbers.length != 0 ?
                                                <ul className="number-draw-list">
                                                    {state.draws.draw_1_numbers.map((number) => <li>{number}</li>)}
                                                </ul>
                                               : 
                                               <p>No Draw Found</p>
                                              }
                                          </div>
                                          }
                                      </div>
                                  </div>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_2} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_2_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.rounds.game_2_numbers.length != 0 ?
                                              <ul className="number-list">
                                                  {state.rounds.game_2_numbers.map((number) =><li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Entry Found</p>
                                              }
                                          </div>
                                          }

                                          <p className="contest-card__name__right">Draw Result</p>
                                          {state.draws && state.draws.draw_2_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.draws.draw_2_numbers.length != 0 ?
                                              <ul className="number-draw-list">
                                                  {state.draws.draw_2_numbers.map((number) =><li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Draw Found</p>
                                              }
                                          </div>
                                          }
                                      </div>
                                  </div>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_3} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_3_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.rounds.game_3_numbers.length != 0 ?
                                              <ul className="number-list">
                                                  {state.rounds.game_3_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Entry Found</p>
                                              }
                                          </div>
                                          }
                                          <p className="contest-card__name__right">Draw Result</p>
                                            {state.draws && state.draws.draw_3_numbers &&
                                            <div className="number-list-wrapper">
                                            {state.draws.draw_3_numbers.length != 0 ?
                                              <ul className="number-draw-list">
                                                  {state.draws.draw_3_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Draw Found</p>
                                              }
                                            </div>
                                            }
                                      </div>
                                  </div>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_4} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_4_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.rounds.game_4_numbers.length != 0 ?
                                              <ul className="number-list">
                                                  {state.rounds.game_4_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Entry Found</p>
                                              }
                                          </div>
                                          }
                                          <p className="contest-card__name__right">Draw Result</p>
                                            {state.draws && state.draws.draw_4_numbers &&
                                            <div className="number-list-wrapper">
                                            {state.draws.draw_4_numbers.length != 0 ?
                                              <ul className="number-draw-list">
                                                  {state.draws.draw_4_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Draw Found</p>
                                              }
                                            </div>
                                            }
                                      </div>
                                  </div>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_5} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_5_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.rounds.game_5_numbers.length != 0 ?
                                              <ul className="number-list">
                                                  {state.rounds.game_5_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Entry Found</p>
                                              }
                                          </div>
                                          }
                                          <p className="contest-card__name__right">Draw result</p>
                                            {state.draws && state.draws.draw_5_numbers &&
                                            <div className="number-list-wrapper">
                                            {state.draws.draw_5_numbers.length != 0 ?
                                              <ul className="number-draw-list">
                                                  {state.draws.draw_5_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Draw Found</p>
                                              }
                                            </div>
                                            }
                                      </div>
                                  </div>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_6} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_6_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.rounds.game_6_numbers.length != 0 ?
                                              <ul className="number-list">
                                                  {state.rounds.game_6_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Entry Found</p>
                                              }
                                          </div>
                                          }
                                          <p className="contest-card__name__right">Draw Result</p>
                                            {state.draws && state.draws.draw_6_numbers &&
                                            <div className="number-list-wrapper">
                                            {state.draws.draw_6_numbers.length != 0 ?
                                              <ul className="number-draw-list">
                                                  {state.draws.draw_6_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Draw Found</p>
                                              }
                                            </div>
                                            }
                                      </div>
                                  </div>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_7} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_7_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.rounds.game_7_numbers.length != 0 ?
                                              <ul className="number-list">
                                                  {state.rounds.game_7_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Entry Found</p>
                                              }
                                          </div>
                                          }
                                          <p className="contest-card__name__right">Draw Result</p>
                                            {state.draws && state.draws.draw_7_numbers &&
                                            <div className="number-list-wrapper">
                                            {state.draws.draw_7_numbers.length != 0 ?
                                              <ul className="number-draw-list">
                                                  {state.draws.draw_7_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Draw Found</p>
                                              }
                                            </div>
                                            }
                                      </div>
                                  </div>
                              </div>
                              {/* end */}
                              {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_8} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_8_numbers &&
                                          <div className="number-list-wrapper">
                                             {state.rounds.game_8_numbers.length != 0 ?
                                              <ul className="number-list">
                                                  {state.rounds.game_8_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Entry Found</p>
                                              }
                                          </div>
                                          }
                                          <p className="contest-card__name__right">Draw Result</p>
                                          {state.draws && state.draws.draw_8_numbers &&
                                          <div className="number-list-wrapper">
                                              {state.draws.draw_8_numbers.length != 0 ?
                                              <ul className="number-draw-list">
                                                  {state.draws.draw_8_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Draw Found</p>
                                              }
                                          </div>
                                          }
                                      </div>
                                  </div>
                              </div>
                              {/* sned */}
                              {/* start */}
                              <div className="col-xl-4 col-md-6 mb-30">
                                  <div className="contest-card">
                                      <a href="contest-details-one.html" className="item-link"></a>
                                      <div className="contest-card__thumb">
                                          <img src={prize_9} alt="image"/>
                                      </div>
                                      <div className="contest-card__footer">
                                          <p className="contest-card__name__right">Your Entry</p>
                                          {state.rounds && state.rounds.game_9_numbers &&
                                          <div className="number-list-wrapper">
                                           {state.rounds.game_9_numbers.length != 0 ?
                                              <ul className="number-list">
                                                  {state.rounds.game_9_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Entry Found</p>
                                              }
                                          </div>
                                          }
                                          <p className="contest-card__name__right">Draw Result</p>
                                          {state.draws && state.draws.draw_9_numbers &&
                                          <div className="number-list-wrapper">
                                            {state.draws.draw_9_numbers.length != 0 ?
                                              <ul className="number-draw-list">
                                                  {state.draws.draw_9_numbers.map((number) => <li>{number}</li>)}
                                              </ul>
                                              :
                                              <p>No Draw Found</p>
                                              }
                                          </div>
                                          }
                                      </div>
                                  </div>
                              </div>
                              {/* end */}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        }
      </div>

      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}
    </div>
    </section>
    </>
  );
};

export default withAuthenticationRequired(MyEntries, {
  onRedirecting: () => <Loading />,
});
