import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import AdSense from 'react-adsense';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Redirect } from 'react-router-dom';
import { getConfig } from "../config";
import Loading from "../components/Loading";
import CountDownTimer from "../components/CountDownTimer";
import SecondsCountDownTimer from "../components/SecondsCountDownTimer";


import axios from 'axios';
import $ from 'jquery';

import prize_1 from "../assets/images/prizes/prize_round_1.png";
import prize_2 from "../assets/images/prizes/prize_round_2.png";
import prize_3 from "../assets/images/prizes/prize_round_3.png";
import prize_4 from "../assets/images/prizes/prize_round_4.png";
import prize_5 from "../assets/images/prizes/prize_round_5.png";
import prize_6 from "../assets/images/prizes/prize_round_6.png";
import prize_7 from "../assets/images/prizes/prize_round_7.png";
import prize_8 from "../assets/images/prizes/prize_round_8.png";
import prize_9 from "../assets/images/prizes/prize_round_9.png";


export const Games = () => {
    const {apiOrigin = "https://api.luckyrewards.co"} = getConfig();

    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true)
    const [show_modal, setShowModal] = useState(false);
    const [show_vip_modal, setShowVipModal] = useState(false)
    const [user, setUser] = useState([])
    const [add_time, setAddTime] = useState(0)
    const [ad_url, setAdUrl] = useState("")
    const [accupixel_url, setAccupixelUrl] = useState("")
    const [redirect_url, setRedirectUrl] = useState("")
    const [redirect_user, setRedirectUser] = useState(false)
    const [timer_count, setTimer] = useState(add_time);
    const [current_round, setCurrentRound] = useState(1)
    const [current_game, setCurrentGame] = useState(1)
    const [game_boards, setGameBoards] = useState([])
    const [game_error, setCreateGameError] = useState('')
    const [games_completed, setGamesCompleted] = useState(false)
    const [verify_email, setVerifyEmail] = useState(false)
    const [add, setAdd] = useState(false);
    const [choices, setChoices] = useState({});
    const [error, setError] = useState('');
    const [get_i, setGameI] = useState('')
    const [subscription_inprogress, setSubscriptionInProgress] = useState(false)
    const [verify_msisdn, setVerifyMsisdn] = useState(false)
    const [show_subscribe, setSubscribe] = useState(true)
    const [whatsapp_subscribe, setWhatsappSubscribe] = useState(true)
    const [notification_subscribe, setNotificationSubscribe] = useState(true)
    const [msisdn, setMsisdn] = useState("")
    const [isSubscribed, setIsSubscribed] = useState(false)


    const handleMsisdnChange = (e) => { setMsisdn(e.target.value)}

    function pad(num) {
        return ("0" + parseInt(num)).substr(-2);
    }

    var start = new Date;
    start.setHours(20, 0, 0); // 7pm
    var now = new Date;

    if (now > start) { // too late, go to tomorrow
        start.setDate(start.getDate() + 1);
    }

    var remain = ((start - now) / 1000);
    var hh = pad((remain / 60 / 60) % 60);
    var mm = pad((remain / 60) % 60);
    var ss = pad(remain % 60);
    
    const hoursMinSecs = {hours:hh, minutes: mm, seconds: ss}

    const total_games = 9
    const total_rounds = 1
    const game_Settings = {
        rounds: 8,
        choicesPP: 6
    }

    const games_config = [
        {
            game_number: 1,
            numbers: 30,
            winnings: '2,500',
            title: 'R2,500',
            description: 'Win a R2,500 Cash Prize',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505782;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_1
        },
        {
            game_number: 2,
            numbers: 35,
            title: 'R5,000',
            description: 'Win a R5,000 Cash Prize',
            winnings: '5000',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_2
        },
        {
            game_number: 3,
            numbers: 40,
            winnings: '10,000',
            title: 'R10,000',
            description: 'Win a R10,000 Cash Prize',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505785;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_3
        },
        {
            game_number: 4,
            numbers: 50,
            winnings: '15,000',
            title: 'R15,000',
            description: 'Win a R15,000 Cash Prize',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505786;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_4
        },
        {
            game_number: 5,
            numbers: 55,
            winnings: '20,000',
            title: 'R20,000',
            description: 'Win a R20,000 Cash Prize',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505787;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_5
        },
        {
            game_number: 6,
            numbers: 60,
            winnings: '40,000',
            title: 'R40,000',
            description: 'Win a R40,000 Cash Prize',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505787;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_6
        },
        {
            game_number: 7,
            numbers: 65,
            winnings: '80,000',
            title: 'R80,000',
            description: 'Win a R80,000 Cash Prize',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505787;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_7
        },
        {
            game_number: 8,
            numbers: 85,
            winnings: '750,000',
            title: 'R750,000',
            description: 'Win a R750,000 Cash Prize',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505787;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_8
        },
        {
            game_number: 9,
            numbers: 95,
            winnings: '1,000,000',
            title: 'R1,000,000',
            description: 'Win a R1,000,000 Cash Prize',
            advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505787;type=json;",
            mobile_advert: "https://servedbyadbutler.com/adserve/;ID=176607;size=0x0;setID=505784;type=json;",
            image: prize_9
        },
    ]

    const handleCloseModal = () => {
        setShowModal(false)
        $('.modal').removeClass('show');
        $('.modal').css('display', 'none');
    }

    useEffect(() => {
        (async () => {

            let user = await getUser();
            console.log('get user', user)

            if(user) {
                if(user.whatsapp_daily_draws == 1 || user.whatsapp_daily_entries == 1 || user.whatsapp_marketing == 1) {
                    setSubscribe(false)
                    setWhatsappSubscribe(false)
                }
            }

            const subscribedDate = user.subscribed_date
            const unSubscribedDate = user.unsubscribed_date
            if (subscribedDate && !unSubscribedDate) {
                setIsSubscribed(true) 
            }


            if (user && user.rounds && user.rounds != 0) {

                setCurrentRound(user.rounds.length)
                let user_choices = {}

                console.log('user', user)

                if (user.rounds.length == 1) {
                    
                    if(user.rounds[0].games_played == 9) {
                        setGamesCompleted(true)
                        setRedirectUser(true)
                    } else {
                        let current_game = user.rounds[0].games_played + 1
                        setCurrentGame(current_game)
    
                        for (let i = 0; i < user.rounds[0].games.length; i++) {
                            let game = user.rounds[0].games[i];
                            let game_numbers = game.numbers.split(',')
                            user_choices[i] = game_numbers
                        }
    
                        setChoices(user_choices)
                    }

                } else {
                    setCreateGameError('Error occured')
                }
            }
            setGameBoards(await getGameBoard(games_config))
            setLoading(false)
        })()
    }, []);

    const getGameBoard = async (games_config) => {
        console.log(`getGameBoard`)
        try {

            let game_boards = []
            for (const game_board of games_config) {

                let board = {}
                let draw_numbers = []

                board.title = game_board.title
                board.description = game_board.description
                board.winnings = game_board.winnings
                board.game_number = game_board.game_number
                board.advert = game_board.advert
                board.mobile_advert = game_board.mobile_advert
                board.image = game_board.image
                board.draw_numbers = draw_numbers


                for (let i = 0; i < game_board.numbers; i++) {
                    let draw_number = i + 1;
                    draw_numbers.push(draw_number)
                }

                game_boards.push(board)
            }

            return game_boards

        } catch (error) {

        }
    }

    const getUser = async () => {
        try {

            let token = await getAccessTokenSilently()
            let config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }

            let response = await axios.get(`${apiOrigin}/users/id`, config)
            setUser(response.data[0])
            return response.data[0]

        } catch (error) {

        }
    };

    const randomNumber = async (choices, total) => {
        let random = Math.floor(Math.random() * total);
        if (choices && choices.includes(random)) return randomNumber(choices, total)
        if (random == 0) return randomNumber(choices, total)
        return random
    }

    const quickPick = async (i) => {
        if(isSubscribed) {
            let new_choices = []
            for (let ii = 1; ii <= game_Settings.choicesPP; ii++) {
                let random = await randomNumber(new_choices, games_config[i].numbers)
                new_choices.push(random)
            }
            setChoices({...choices, [i]: new_choices})
        } else {
            setShowVipModal(true);
            $('.modal-vip').addClass('show');
            $('.modal-vip').css('display', 'block');
        }

    }

    const joinVIP = async () => {
        setShowVipModal(true);
        $('.modal-vip').addClass('show');
        $('.modal-vip').css('display', 'block');
    }

    const clear = async (i) => {
        setChoices({...choices, [i]: []});
    }

    const showAd = async (i) => {
        if (choices[i].length == game_Settings.choicesPP) {
            setAdd(!add)
            let timeleft = 10;
            setTimeout(function () {
                $('.submit_btn_game').css('display', 'inline-block');
                if(timeleft <= 0){
                    $(".countdown").innerHTML = "";
                } else {
                    $(".countdown").innerHTML = timeleft + " seconds remaining";
                }
                timeleft -= 1;
            }, 10000)
        }
    }

    const checkUserMail = async () => {
        let user = await getUser()
        console.log(user)
        console.log(get_i)
        if (user.email_verified == 1) {
            setShowModal(false)
            $('.modal').removeClass('show');
            $('.modal').css('display', 'none');
            await submitGame(get_i)
        }
    }

    const submitGame = async (i) => {
        let token = await getAccessTokenSilently()
        setGameI(i)
        if (user.email_verified == 0 ) {

            
                setVerifyEmail(true)
                let user_id;

                if (user.auth0) user_id = user.auth0
                if (user.facebook) user_id = user.facebook
                if (user.google) user_id = user.google

                console.log(user_id)

                let response = await axios.post(`${apiOrigin}/email/verify`, {
                  user_id: user_id
                }, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  }
                })

                if(response) {
                    setShowModal(true)
                    $('.modal').addClass('show');
                    $('.modal').css('display', 'block');
                }
            
        } else {

            let response = await axios.post(`${apiOrigin}/games`, {
                round_number: current_round,
                game_number: current_game,
                numbers: choices[i]
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })

            if (response.data == `Update Round ${current_round} with Game ${current_game}`) {
                if (current_round == 1 && current_game == 9) {
                    setGamesCompleted(true)
                    setRedirectUser(true)
                } else {
                    setCurrentGame(current_game + 1)
                }
                setAdd(false)
            } else {
                setCreateGameError('A error occured please try agian later.')
            }
        }
    }

    const selectNumber = (i, ii) => {
        let new_choices = choices[i] ? choices[i] : []
        if (new_choices.length >= game_Settings.choicesPP) return false
        if (new_choices.includes(ii)) return false
        new_choices.push(ii)
        setChoices({...choices, [i]: new_choices});
    }

    const whatsappSubsribe = async () => {
        console.log('whatsappSubsribe')

        if(user.msisdn) {
            window.open('https://wa.me/27729218045?text=Hello', '_blank');
            // setWhatsappRedirect(true)
        } else {

            setVerifyMsisdn(true)
            setSubscriptionInProgress(true)
            setShowModal(true)
            $('.modal').addClass('show');
            $('.modal').css('display', 'block');
        
        }
    }

    const checkUserWhatsapp = async () => {
        let token = await getAccessTokenSilently()
        let response = await axios.put(`${apiOrigin}/users/id`, {
            msisdn: msisdn,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
    
        if(response.status == 200) { 
            if(response.data.msisdn) {
                setShowModal(false)
                $('.modal').removeClass('show');
                $('.modal').css('display', 'none');
                setSubscriptionInProgress(false)
                setVerifyMsisdn(false)
                window.open('https://wa.me/27729218045?text=Hello', '_blank');
            }
        } 
    }

    if(loading) return <Loading/>
    
    return (
        <section className="position-relative pt-150 pb-120 background-blue-contest">
            <div className="container pt-120">
                {!games_completed ?
                    <div className="row justify-content-center">
                        {redirect_user ? (<Redirect push to="/my-entries"/>) : null}
                        <div className="container wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                            {game_boards.map((board, i) =>
                                <div className={board.game_number == current_game ? 'col-lg-12 game-card-wrapper mb-50 game_active' : 'col-lg-12 game-card-wrapper mb-50'}>
                                    {/* game-card-header */}
                                    <div className="game-card-header">
                                        <div className="container">
                                        {!add &&
                                            <div className="col-lg-12">
                                                <div className="row game-prize justify-content-center">
                                                    <div className="right col-lg-2">
                                                        <img className="game-prize-img" src={board.image}/>
                                                    </div>
                                                    <div className="right col-lg-10">
                                                        <h3 className='contest-card-game'>Game {board.game_number}</h3>
                                                        <h5 className="contest-card__name">{board.title}</h5>
                                                        <p className="game-prize-title">{board.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="divider"></div>
                                        </div>


                                        <div className="container bottom justify-content-center">
                                            {choices.hasOwnProperty(i) == true && choices[i].length != 0 ?
                                                <>
                                                    {!add &&
                                                    <ul className="game-single__selected-number justify-content-center">
                                                        {choices[i].map((choice, index) => 
                                                        <li key_1={(i + 100)} className="active">{choice}</li>)}
                                                    </ul>
                                                    }
                                                </>
                                                :
                                                <div className="justify-content-center">
                                                    <p className="sub-title">Choose 6 numbers for Game {current_game} of {total_games}</p>
                                                </div>
                                            }
                                        </div>


                                    </div>
                                    {/* game-card-header */}

                                    {/* game-card-body */}
                                    {current_game == (i + 1) &&
                                    <div className="game-card-body">
                                        {add && !isSubscribed ?
                                            <div className={'row game_add_' + i}>
                                                <AdSense.Google
                                                    client='ca-pub-5667594408223005'
                                                    slot='1917786634'
                                                    style={{ display: 'block', width: '100%' }}
                                                    format='fluid'
                                                    responsive='true'
                                                />
                                            </div>
                                            :
                                            <div className="justify-content-center">
                                                <div className="divider small-divider"></div>
                                                <ul className="game-single__number">
                                                    {board.draw_numbers.map((item2, ii) =>
                                                        <li key={ii++}
                                                            className={choices[i] && choices[i].includes(ii) ? 'active' : ''}
                                                            onClick={() => selectNumber(i, (ii))}>
                                                            {ii}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    }
                                    {/* game-card-body */}

                                    <div className="divider"></div>

                                    {/* game-card-footer */}
                                    <div className="game-card-footer">
                                        <div className="container">
                                            {current_game == (i + 1) &&
                                            <div className="row">
                                                <div className="left col-6">
                                                    {(current_game == (i + 1) && !add) &&
                                                    <div>
                                                        {!choices[i] || choices[i].length == 0 ?
                                                            <ul className="game-buttons">
                                                                <li>
                                                                    <button onClick={(e) => quickPick(i)}><i
                                                                        className="las la-random px-2"></i> <span>Quick Pick</span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            :
                                                            <ul>
                                                                <li>
                                                                    <button onClick={(e) => clear(i)}><i
                                                                        className="las la-undo-alt px-2"></i> <span>Clear</span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        }
                                                    </div>
                                                    }
                                                    {(current_game == (i + 1) && add) &&
                                                    <div>
                                                        {!isSubscribed &&
                                                            <ul className="game-buttons">
                                                                <li>
                                                                    <button className="joinVIP" onClick={(e) => joinVIP()}>Don't want to see ads ? <span>Join VIP now.</span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        }
                                                    </div>
                                                    }
                                                </div>

                                                {!choices[i] || choices[i].length != 0 &&
                                                <div className="right col-6">
                                                    <div className="countdown"></div>
                                                    <ul className="game-buttons">
                                                        {!add ?
                                                            <li>
                                                                {!isSubscribed ? 
                                                                    <button onClick={(e) => showAd(i)}><i className="las la-play px-2"></i> <span>Continue</span></button>
                                                                :
                                                                    <button onClick={(e) => submitGame(i)}> <i className="las la-play px-2"></i> <span>Submit</span></button>
                                                                }
                                                            </li>
                                                            :
                                                            <>
                                                                <SecondsCountDownTimer hoursMinSecs={10} />
                                                                <li className="submit_btn_game">
                                                                    <button onClick={(e) => submitGame(i)}> <i className="las la-play px-2"></i> <span>Submit</span></button>
                                                                </li>
                                                            </>
                                                        }
                                                    </ul>
                                                </div>
                                                }
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {/* game-card-footer */}
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-header text-center">
                                    <h2 className="section-title">All Games Completed</h2>
                                    <p className="section-sub-title">You have played all games for today's draw, please remember to come back and check your lucky numbers after 8pm (SAST)</p>
                                    <CountDownTimer hoursMinSecs={hoursMinSecs}/>
                                </div>
                                {!isSubscribed && 
                                <div className="isSubsribed text-center">
                                    <h2 className="section-title-vip">Become a VIP</h2>
                                    <p className="section-sub-title-vip">As a VIP member and we will call or email you if you have winning numbers, no need to come back and check<br/>Automatic lucky reward number generation everyday if you forget to play<br/>Plus lots more!</p>
                                    <a href="/vip" className="cmn-btn style--two">Join VIP Now</a>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="modal fade" tabIndex="1" role="dialog" aria-hidden={show_modal}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="account-form-area">
                                <button type="button" className="close-btn" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                    <i className="las la-times"></i>
                                </button>
                                <h3 className="title">Complete Your Account</h3>
                                <div className="account-form-wrapper">
                                    {verify_email &&
                                        <>
                                            <div className="form-group">
                                                <p>Please verify your email by following the link sent to you on {user.email}</p>
                                            </div>

                                            <div className="form-group text-center mt-5">
                                                <button className="cmn-btn" onClick={(e) => checkUserMail()}>Continue</button>
                                            </div>
                                        </>
                                    }

                                    {verify_msisdn && subscription_inprogress &&
                                        <>
                                            <div className="form-group">
                                                <p>Please verify your whatsapp number by inputing it below</p>
                                            </div>

                                            <div className="form-group">
                                                <label>Mobile Number <sup>*</sup></label>
                                                <input type="text" name="msisdn"  value={msisdn} placeholder="Enter Your Mobile Number" onChange={handleMsisdnChange} />
                                            </div>

                                            <div className="form-group text-center mt-5">
                                                <button className="cmn-btn" onClick={(e) => checkUserWhatsapp()}>Continue</button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-vip modal fade" tabIndex="1" role="dialog" aria-hidden={show_vip_modal}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="account-form-area">
                                <button type="button" className="close-btn" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                    <i className="las la-times"></i>
                                </button>
                                <h3 className="title">Join VIP Now</h3>
                                <div className="account-form-wrapper">    
                                    <div className="form-group">
                                        <div className="hero__content">
                                            <ul>
                                                <li>Access to the VIP Area where you can play additional games</li>
                                                <li>Automatic lucky reward number generation everyday if you forget to play</li>
                                                <li>We will call and email you if you have winning numbers, no need to come back and check</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-group text-center mt-5">
                                        <div className="hero__btn">
                                            <a href={"http://doi.mtndep.co.za/service/8428/?ext_ref=" + user.id} className="cmn-btn style--two">Join VIP</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withAuthenticationRequired(Games, {
    onRedirecting: () => <Loading/>,
});
