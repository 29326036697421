import React from "react";

import how_to_play_icon_1 from '../assets/images/icon/play/register.png'
import how_to_play_icon_2 from '../assets/images/icon/play/play.png'
import how_to_play_icon_3 from '../assets/images/icon/play/check.png'
import how_to_play_icon_4 from '../assets/images/icon/play/win.png'

const HowToPlay = () => (
    <section className="position-relative pt-120 pb-120 overflow-hidden">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-sm-left text-center">
                    <div className="section-header-blue">
                        <h2 className="section-title-blue">How To Play</h2>
                        <p>
                            Lucky Rewards gives you the chance to win some amazing prizes every day. Each day,
                            you get to choose your lucky numbers in our 10 FREE to play games. Every evening at 6pm (SAST),
                            the entries for the day are closed and our lucky number generator draws the winning numbers.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row mb-none-30 justify-content-xl-start justify-content-center">
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div className="play-card play-card-blue">
                        <div className="play-card__icon">
                            <img src={how_to_play_icon_1} alt="image-icon"/>
                        </div>
                        <div className="play-card__content">
                            <h3 className="play-card__title">Register</h3>
                            <p>Register with your email address or social logins to play the game</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div className="play-card play-card-red">
                        <div className="play-card__icon">
                            <img src={how_to_play_icon_2} alt="image-icon"/>
                        </div>
                        <div className="play-card__content">
                            <h3 className="play-card__title">Play</h3>
                            <p>Play the 10 lucky rewards games every day before 6pm (SAST)</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div className="play-card play-card-teal">
                        <div className="play-card__icon">
                            <img src={how_to_play_icon_3} alt="image-icon"/>
                        </div>
                        <div className="play-card__content">
                            <h3 className="play-card__title">Check</h3>
                            <p>Come back after 8pm (SAST) to check whether you have WON</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                    <div className="play-card ">
                        <div className="play-card__icon">
                            <img src={how_to_play_icon_4} alt="image-icon"/>
                        </div>
                        <div className="play-card__content">
                            <h3 className="play-card__title">Win</h3>
                            <p>Claim your winning numbers for each of the games</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default HowToPlay;
